import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableLoader from 'components/TableLoader';
import { Typography } from '@mui/material';
import { roundOfData } from 'utils/Config';

interface ListProps {
  data: any;
  loader: boolean;
}

const styleData: any = {
  uppcasetransform: {
    textTransform: 'capitalize',
  },
  corbonSticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
  },
  activitySticky: {
    position: 'sticky',
    left: 0,
    minWidth: 250,
    background: 'white',
  },
  // unitSticky: {
  //   position: 'sticky',
  //   left: 200,
  //   minWidth: 150,
  //   background: 'white',
  // },
  valueSticky: {
    // position: 'sticky',
    // left: 250,
    minWidth: 150,
    background: 'white',
  },
  kgSmall: {
    minWidth: 150,
    background: 'white',
  },
  kgMedium: {
    minWidth: 180,
    background: 'white',
  },
  kgLarge: {
    minWidth: 200,
    background: 'white',
  },
};

const CorbonFootPrintWithFormerScope = (props: ListProps) => {
  const { data, loader } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{
        '&::-webkit-scrollbar': {
          height: '8px',
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#cfcfcf',
          borderRadius: 2,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#fffff',
          borderRadius: 2,
        },
      }}>
      <Table
        stickyHeader
        className='result-sticky-head second-border'
        sx={{ width: '100%' }}
        aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell
              align='center'
              className='blue-header'
              style={styleData.corbonSticky}
              colSpan={2}>
              <Typography component={'div'} sx={{ padding: '10px !important', fontWeight: 'bold' }}>
                Carbon Footprint
              </Typography>
            </TableCell>
            <TableCell align='center' className='blue-header' colSpan={2}>
              <Typography component={'div'} sx={{ padding: '10px !important', fontWeight: 'bold' }}>
                SCOPE 1
              </Typography>
            </TableCell>
            <TableCell align='center' className='blue-header' colSpan={2}>
              <Typography component={'div'} sx={{ padding: '10px !important', fontWeight: 'bold' }}>
                SCOPE 2
              </Typography>
            </TableCell>
            <TableCell align='center' className='blue-header' colSpan={2}>
              <Typography component={'div'} sx={{ padding: '10px !important', fontWeight: 'bold' }}>
                SCOPE 3
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={styleData.activitySticky} align='center' className='ligt-green'>
              Activities
            </TableCell>
            <TableCell style={styleData.valueSticky} align='center' className='ligt-green'>
              Value
            </TableCell>
            <TableCell style={styleData.kgLarge} align='center' className='ligt-green'>
              Activities
            </TableCell>
            <TableCell style={styleData.kgSmall} align='center' className='ligt-green'>
              Value
            </TableCell>
            <TableCell style={styleData.kgLarge} align='center' className='ligt-green'>
              Activities
            </TableCell>
            <TableCell style={styleData.kgSmall} align='center' className='ligt-green'>
              Value
            </TableCell>
            <TableCell style={styleData.kgLarge} align='center' className='ligt-green'>
              Activities
            </TableCell>
            <TableCell style={styleData.kgSmall} align='center' className='ligt-green'>
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loader ? (
            <TableLoader colsSpan={10} />
          ) : (
            <>
              <TableRow>
                <TableCell style={styleData.corbonSticky} align='center' className='ligt-green'>
                  PRE-FARM
                </TableCell>
                <TableCell style={styleData.valueSticky} align='center' className='ligt-green'>
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell align='center' className='ligt-green' colSpan={6}>
                  PRE-FARM
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.preFarm &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.preFarm?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.preFarm
                  .sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order))
                  .map((result: any, index: number) => {
                    if (
                      [
                        'seeds',
                        'productionOfFertilizerUrea-N',
                        'productionOfFertilizerP2O5',
                        'productionOfFertilizerK2O',
                        'productionOfLimeCaCO3',
                        'pesticideProduction',
                        'herbicideProduction',
                        'fuelProduction ',
                        'propaneProduction',
                        'landTransportOfProducts',
                      ].includes(result.key)
                    ) {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            style={{ ...styleData.activitySticky, ...styleData.uppcasetransform }}>
                            {result?.key === 'productionOfFertilizerP2O5' ? (
                              <span>
                                Production of fertilizer P<sub>2</sub>O<sub>5</sub>
                              </span>
                            ) : result?.key === 'productionOfFertilizerK2O' ? (
                              <span>
                                Production of fertilizer K<sub>2</sub>O
                              </span>
                            ) : result?.key === 'productionOfLimeCaCO3' ? (
                              <span>
                                Production of lime CaCO<sub>3</sub>O
                              </span>
                            ) : (
                              result?.activities
                            )}
                          </TableCell>
                          {/* <TableCell style={styleData.unitSticky}>{result?.unit}</TableCell> */}
                          <TableCell align='center' style={styleData.valueSticky}>
                            {roundOfData(result?.value)}
                          </TableCell>
                          <TableCell align='center' style={styleData.kgLarge}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgSmall}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgLarge}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgSmall}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgLarge}>
                            {result?.key === 'productionOfFertilizerP2O5' ? (
                              <span>
                                Production of fertilizer P<sub>2</sub>O<sub>5</sub>
                              </span>
                            ) : result?.key === 'productionOfFertilizerK2O' ? (
                              <span>
                                Production of fertilizer K<sub>2</sub>O
                              </span>
                            ) : result?.key === 'productionOfLimeCaCO3' ? (
                              <span>
                                Production of lime CaCO<sub>3</sub>O
                              </span>
                            ) : (
                              result?.activities
                            )}
                          </TableCell>
                          <TableCell align='center' style={styleData.kgSmall}>
                            {roundOfData(result?.value)}
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return <Typography key={index} />;
                    }
                  })
              ) : (
                <Typography />
              )}
              <TableRow>
                <TableCell style={styleData.corbonSticky} align='center' className='ligt-green'>
                  ON-FARM
                </TableCell>
                <TableCell style={styleData.valueSticky} align='center' className='ligt-green'>
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell align='center' className='ligt-green' colSpan={6}>
                  ON-FARM
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.onFarm &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.onFarm?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.onFarm
                  .sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order))
                  .map((result: any, index: number) => {
                    if (
                      [
                        'emissionsFromFertilizerApplied',
                        'emissionsFromLimeApplied',
                        'dieselConsumptionOnFieldOperations',
                        'propaneInSeedDryingStorage',
                        'waterPumping',
                        'electricityInSeedDryingStorage',
                        'pesticideAndHerbicideDegradation',
                      ].includes(result.key)
                    ) {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            style={{ ...styleData.activitySticky, ...styleData.uppcasetransform }}>
                            {result?.activities}
                          </TableCell>
                          {/* <TableCell style={styleData.unitSticky}>{result?.unit}</TableCell> */}
                          <TableCell align='center' style={{ ...styleData.valueSticky }}>
                            {roundOfData(result?.value)}
                          </TableCell>
                          {[
                            'emissionsFromFertilizerApplied',
                            'emissionsFromLimeApplied',
                            'dieselConsumptionOnFieldOperations',
                            'propaneInSeedDryingStorage',
                          ].includes(result.key) ? (
                            <>
                              <TableCell
                                align='center'
                                style={{ ...styleData.kgLarge, ...styleData.uppcasetransform }}>
                                {result?.activities}
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                {roundOfData(result?.value)}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align='center' style={styleData.kgLarge}>
                                -
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                -
                              </TableCell>
                            </>
                          )}

                          {[
                            'waterPumping',
                            'electricityInSeedDryingStorage',
                            'pesticideAndHerbicideDegradation',
                          ].includes(result.key) ? (
                            <>
                              <TableCell
                                align='center'
                                style={{ ...styleData.kgLarge, ...styleData.uppcasetransform }}>
                                {result?.activities}
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                {roundOfData(result?.value)}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align='center' style={styleData.kgLarge}>
                                -
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                -
                              </TableCell>
                            </>
                          )}

                          <TableCell align='center' style={styleData.kgLarge}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgSmall}>
                            -
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return <Typography key={index} />;
                    }
                  })
              ) : (
                <Typography />
              )}
              <TableRow>
                <TableCell style={styleData.corbonSticky} align='center' className='ligt-green'>
                  ON-PROCESS
                </TableCell>
                <TableCell style={styleData.valueSticky} align='center' className='ligt-green'>
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell align='center' className='ligt-green' colSpan={6}>
                  ON-PROCESS
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.onProcess &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.onProcess?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.onProcess
                  .sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order))
                  .map((result: any, index: number) => {
                    if (
                      [
                        'wasteBurning(in situ)',
                        'transportToProcessing',
                        'electricityConsumptionOnProcessing',
                        'wasteTreatment(to landfill)',
                      ].includes(result.key)
                    ) {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            style={{ ...styleData.activitySticky, ...styleData.uppcasetransform }}>
                            {result?.activities}
                            {result?.unit !== 'kg CO2 eq/year' ? ` - ${result?.unit}%` : ''}
                          </TableCell>
                          {/* <TableCell style={styleData.unitSticky}>{result?.unit}</TableCell> */}
                          <TableCell align='center' style={styleData.valueSticky}>
                            {roundOfData(result?.value)}
                          </TableCell>
                          {['wasteBurning(in situ)'].includes(result.key) ? (
                            <>
                              <TableCell
                                align='center'
                                style={{ ...styleData.kgLarge, ...styleData.uppcasetransform }}>
                                {result?.activities}
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                {roundOfData(result?.value)}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align='center' style={styleData.kgLarge}>
                                -
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                -
                              </TableCell>
                            </>
                          )}
                          <TableCell align='center' style={styleData.kgLarge}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgSmall}>
                            -
                          </TableCell>
                          {[
                            'transportToProcessing',
                            'electricityConsumptionOnProcessing',
                            'wasteTreatment(to landfill)',
                          ].includes(result.key) ? (
                            <>
                              <TableCell
                                align='center'
                                style={{ ...styleData.kgLarge, ...styleData.uppcasetransform }}>
                                {result?.activities}
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                {roundOfData(result?.value)}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align='center' style={styleData.kgLarge}>
                                -
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                -
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    } else {
                      return <Typography key={index} />;
                    }
                  })
              ) : (
                <Typography />
              )}
              <TableRow>
                <TableCell style={styleData.corbonSticky} align='center' className='ligt-green'>
                  POST-PROCESS
                </TableCell>
                <TableCell style={styleData.valueSticky} align='center' className='ligt-green'>
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell align='center' className='ligt-green' colSpan={6}>
                  POST-PROCESS
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.postProcess &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.postProcess?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.postProcess
                  .sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order))
                  .map((result: any, index: number) => {
                    if (['transportationOfProcessedProducts'].includes(result.key)) {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            style={{ ...styleData.activitySticky, ...styleData.uppcasetransform }}>
                            {result?.activities}
                          </TableCell>
                          {/* <TableCell style={styleData.unitSticky}>{result?.unit}</TableCell> */}
                          <TableCell align='center' style={styleData.valueSticky}>
                            {roundOfData(result?.value)}
                          </TableCell>
                          <TableCell align='center' style={styleData.kgLarge}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgSmall}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgLarge}>
                            -
                          </TableCell>
                          <TableCell align='center' style={styleData.kgSmall}>
                            -
                          </TableCell>
                          {['transportationOfProcessedProducts'].includes(result.key) ? (
                            <>
                              <TableCell
                                align='center'
                                style={{ ...styleData.kgLarge, ...styleData.uppcasetransform }}>
                                {result?.activities}
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                {roundOfData(result?.value)}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align='center' style={styleData.kgLarge}>
                                -
                              </TableCell>
                              <TableCell align='center' style={styleData.kgSmall}>
                                -
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    } else {
                      return <Typography key={index} />;
                    }
                  })
              ) : (
                <Typography />
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CorbonFootPrintWithFormerScope;
