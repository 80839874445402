import axios from 'axios';
import { useCustomNavigation } from 'hooks';
import store from 'store';
import { logout, refreshToken } from 'store/actions/UserAction';
import Storage from 'utils/Storage';

const { dispatch }: any = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: any) => {
    const TokenStatus: any = Storage.getTokens();

    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
    if (token) {
      config.headers['Authorization'] = 'Bearer '.concat(token);
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (err: any) => {
    const originalConfig = err.config;
    const responseData = err.response.data;
    if (originalConfig.url !== 'login' && err.response) {
      // Access Token was expired
      if (err.response.data && err.response.data?.error) {
        if (err.response.data?.error?.status && err.response.data?.error?.status === 401) {
          originalConfig._retry = true;
          try {
            const TokenStatus: any = Storage.getTokens();
            const rs = await instance.post('tokens/exchange', {
              refreshToken: TokenStatus && TokenStatus.refreshToken ? TokenStatus.refreshToken : '',
            });
            const { refreshToken: refreshToken1, accessToken } = rs.data;
            const tokenData: any = {
              refreshToken: refreshToken1,
              token: accessToken,
            };
            dispatch(refreshToken(refreshToken1));
            Storage.updateAccessToken(tokenData);
            return instance(originalConfig);
          } catch (_error) {
            logout(dispatch);
            Storage.clear();
            useCustomNavigation('/login', { replace: true });
          }
        }
      }
      if (err.response.data?.error?.status === 403) {
        logout(dispatch);
        Storage.clear();
        useCustomNavigation('/login', { replace: true });
      }
      if (responseData.error?.message && responseData.error?.message === 'Invalid Token!') {
        logout(dispatch);
        Storage.clear();
        useCustomNavigation('/login', { replace: true });
      }
    }
    return Promise.reject(err);
  },
);
export default instance;
