import Storage from 'utils/Storage';

import {
  EMAIL_CHECKED,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_ORGANIZATION_LOGO,
  USER_REFRESH_TOKEN,
} from '../types/UserTypes';

const user = Storage.isTokenValidDetails();
export interface UserState {
  loading?: boolean;
  userInfo?: any;
  isLoggedIn?: boolean;
  emailRemember?: any;
  organizationLogo?: any;
}

export interface Action {
  type: string;
  payload?: string;
}

const INITIAL_STATE: UserState = {
  emailRemember: Storage.getItem(Storage.KEYS.REMEMBER_ME),
  isLoggedIn: Storage.isTokenValidDetails() ? true : false,
  loading: false,
  userInfo: Storage.isTokenValidDetails() ? Storage.isTokenValidDetails() : null,
  organizationLogo: Storage.getItem(Storage.KEYS.ORGANIZATION_LOGO),
};

export const userLoginReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        // emailRemember: Storage.getItem(Storage.KEYS.REMEMBER_ME),
        isLoggedIn: false,
        loading: false,
        userInfo: {},
      };
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        emailRemember: state.emailRemember,
        error: action.payload,
        isLoggedIn: false,
        loading: false,
        userInfo: null,
      };
    case USER_REFRESH_TOKEN:
      return {
        ...state,
        userInfo: { ...user, token: action.payload },
      };
    case EMAIL_CHECKED:
      return {
        ...state,
        emailRemember: action.payload,
      };
    case USER_ORGANIZATION_LOGO:
      return {
        ...state,
        organizationLogo: action.payload,
      };
    default:
      return state;
  }
};
