import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { Vortex } from 'react-loader-spinner';

interface LoaderProps {
  colsSpan: number;
}

const TableLoader = (props: LoaderProps) => {
  const { colsSpan } = props;
  return (
    <TableRow>
      <TableCell align='center' colSpan={colsSpan}>
        <Vortex
          visible={true}
          height="80"
          width="80"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={['#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1']}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableLoader;
