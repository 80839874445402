import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';

interface ModalProps {
  onClose?: (isValid?: boolean) => void;
  onSubmit?: () => void;
  heading: any;
  subHeading: any;
  btnName1?: string;
  btnName2?: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: ' 6px',
  background: ' #FFF',
  boxShadow: '0px 2px 4px 0px rgba(147, 147, 147, 0.25)',
  padding: '24px',
};

const ConfirmationModel = (props: ModalProps) => {
  const { heading, onSubmit, onClose, subHeading, btnName1, btnName2 } = props;
  return (
    <Modal
      open={true}
      onClose={() => onClose && onClose(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={style} className='mobileOut'>
        <Typography className='modal-title'>{heading}</Typography>
        <Typography className='modal-dis'>{subHeading}</Typography>
        <Typography component={'div'} className='in-flex flex-end'>
          <Button className='cancel-btn btn-2r' onClick={() => onClose && onClose(true)}>
            {btnName1 ? btnName1 : 'Cancel'}
          </Button>
          <Button className='save-btn' onClick={onSubmit}>
            {btnName2 ? btnName2 : 'Save'}
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ConfirmationModel;
