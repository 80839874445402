import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import Delete from 'assets/png/delete.png';
import React, { useEffect } from 'react';
import CustomInput from 'components/CustomInput/customInput';
// import ArrowDown from 'assets/png/arrow_down.svg';
import Close from 'assets/png/close.png';
import CustomSelect from 'components/CustomSelect';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { APIKEY, alertOptions, roundOfData1, roundOfData2 } from 'utils/Config';
import { toast } from 'react-toastify';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import {
  FacilityModelType,
  FacilityType,
  SoilHumidity,
  WasteTreatment,
  YesOrNoOption,
} from 'utils/ArrayContans';
import { yupResolver } from '@hookform/resolvers/yup';
import { facilitiesSchema } from 'utils/ValidatorSchema';
import { Controller, useForm } from 'react-hook-form';
import { facilityForm } from 'types';
import Loader from 'components/Loader';
import { PrivateRoutes } from 'routes/routeConstans';
import TableLoader from 'components/TableLoader';
import _ from 'lodash';
import AddIconNumber from 'assets/png/Addplus.png';
import ConfirmationModel from 'components/ConfirmationModal';
import Autocomplete from 'react-google-autocomplete';
import { convertDistance, getDistance } from 'geolib';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: ' 6px',
  background: ' #FFF',
  boxShadow: '0px 2px 4px 0px rgba(147, 147, 147, 0.25)',
  padding: '24px',
};

const AddScenario = () => {
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const param: any = useParams();
  const [addOrView, setAddOrView] = React.useState('add');
  const [cultivationProd, setCultivationProd] = React.useState('');
  const [value, setValue] = React.useState('1');
  const [farmList, setFarmList] = React.useState<any>([]);
  const [processList, setProcessList] = React.useState<any>([]);
  const [redirectId, setRedirectId] = React.useState<string>('');
  const [editOption, setEditOption] = React.useState<boolean>(false);
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    if (!_.isEmpty(location.state)) {
      const editData = location.state.row;
      setBodyData(editData);
      setRedirectId(editData?._id);
      if (editData?.facilityDetails && editData?.facilityDetails?.length) {
        const facilityData = editData?.facilityDetails.map((item: any) => {
          return item?.facility;
        });
        const frmData = facilityData.filter((item: any) => item.type === 'farm');
        setFarmList(frmData);
        const prcsData = facilityData.filter((item: any) => item.type === 'processingPlant');
        setProcessList(prcsData);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (param.type !== 'add') {
      if (param.type === 'edit' || param.type === 'view') {
        setAddOrView(param.type);
      } else {
        navigate(-1);
      }
    } else {
      getList();
    }
  }, [param]);

  const { handleSubmit, control, formState, reset } = useForm<facilityForm | any>({
    defaultValues: {
      name: '',
      type: '',
      address: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(facilitiesSchema),
  });

  const submitForm = (data: any) => {
    setLoader(true);
    data['status'] = true;
    addNewData(data, MASTER_ENDPOINT.facility, '', true)
      .then(() => {
        setLoader(false);
        getList();
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const [opendelete, setOpendelete] = React.useState(false);
  const handledeleteOpen = () => setOpendelete(true);
  const handledeleteClose = () => setOpendelete(false);
  const [bodyData, setBodyData] = React.useState<any>({
    name: '',
    date: moment().format('MM/DD/YYYY'),
    comments: '',
    facilityDetails: [],
    standards: [],
  });

  useEffect(() => {
    if (
      value === '3' &&
      addOrView === 'add' &&
      bodyData.facilityDetails?.[0]?.['cultivationProd'] &&
      cultivationProd !== bodyData.facilityDetails?.[0]?.['cultivationProd']
    ) {
      setCultivationProd(bodyData.facilityDetails?.[0]?.['cultivationProd']);
      getStandardDetails(bodyData.facilityDetails?.[0]?.['cultivationProd']);
    }
  }, [value]);

  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const getStandardDetails = async (standData: string) => {
    setTableLoading(true);
    setBodyData({ ...bodyData, standards: [] });
    await getAllListData(`${MASTER_ENDPOINT.Standards}?order=asc&sortby=id`).then((res: any) => {
      if (standData === 'dualPurpose') {
        const keysToRemove = [
          '_id',
          'updatedBy',
          'updatedAt',
          'status',
          'deletedBy',
          'deletedAt',
          'createdBy',
          'createdAt',
        ];
        const newArray = res?.data?.[standData].map((obj: any) => {
          const newObj = { ...obj }; // Create a copy of the original object
          keysToRemove.forEach((key) => delete newObj[key]); // Delete the specified keys
          return newObj;
        });
        setBodyData({ ...bodyData, standards: newArray });
      } else {
        const keysToRemove = [
          '_id',
          'updatedBy',
          'updatedAt',
          'status',
          'deletedBy',
          'deletedAt',
          'createdBy',
          'createdAt',
        ];
        const newArray = res?.data?.['dualPurpose'].map((obj: any) => {
          const newObj = { ...obj }; // Create a copy of the original object
          keysToRemove.forEach((key) => delete newObj[key]); // Delete the specified keys
          return newObj;
        });
        const newArray1 = res?.data?.[standData].map((obj: any) => {
          const newObj = { ...obj }; // Create a copy of the original object
          keysToRemove.forEach((key) => delete newObj[key]); // Delete the specified keys
          return newObj;
        });
        const overAllArray = newArray1.concat(newArray);
        setBodyData({ ...bodyData, standards: overAllArray });
      }
    });
    setTableLoading(false);
  };

  const getList = () => {
    getAllListData(`${MASTER_ENDPOINT.facility}`).then((res: any) => {
      const frmData = res?.data.filter((item: any) => item.type === 'farm');
      setFarmList(frmData);
      if (frmData.length) {
        facilityNext(frmData[0]);
      }
      const prcsData = res?.data.filter((item: any) => item.type === 'processingPlant');
      setProcessList(prcsData);
    });
  };

  const backTab = () => {
    if (value === '1') {
      navigate(-1);
    } else {
      value === '2' ? setValue('1') : setValue('2');
    }
  };

  const submitValidate = async (tabVal?: any) => {
    if (tabVal) {
      if (tabVal === 1) {
        if (!checkCondition(bodyData.name.trim())) {
          toast.error('Name is required', alertOptions);
          return false;
        }
        return true;
      } else if (tabVal === 2) {
        if (bodyData?.facilityDetails?.length === 0) {
          toast.error('Please add farm facility details', alertOptions);
          return false;
        }
        return await formTwoValidation();
      }
    } else {
      if (!checkCondition(bodyData.name.trim())) {
        setValue('1');
        toast.error('Name is required', alertOptions);
        return false;
      } else if (bodyData?.facilityDetails?.length === 0) {
        setValue('2');
        toast.error('Please add farm facility details', alertOptions);
        return false;
      }
      return await formTwoValidation();
    }
  };

  const submitTab = async () => {
    if (addOrView !== 'view') {
      if (value === '1') {
        (await submitValidate(1)) && setValue('2');
      } else if (value === '2') {
        (await submitValidate(2)) && setValue('3');
      } else {
        if (await submitValidate()) {
          submitAllTab();
        }
      }
    } else {
      value === '1' ? setValue('2') : setValue('3');
    }
    // if (value === '1') {
    //   if (bodyData.name.trim()) {
    //     setValue('2');
    //   } else {
    //     toast.error('Name is required', alertOptions);
    //   }
    // } else {
    //   if (value === '2' && bodyData?.facilityDetails?.length === 0) {
    //     toast.error('select facility', alertOptions);
    //     return true;
    //   } else if (value === '2') {
    //     if (addOrView === 'add') {
    //       if (await formTwoValidation()) {
    //         setValue('3');
    //       }
    //     } else {
    //       setValue('3');
    //     }
    //   } else {
    //     submitAllTab();
    //   }
    // }
  };

  const checkCondition = (val: any) => {
    if (val === 'undefined' || val === '' || val === null) {
      return false;
    }
    return true;
  };

  // const editStandardFunctions = () => {
  //   setLoader(true);
  //   updateData(redirectId, { standards: bodyData.standards }, MASTER_ENDPOINT.Scenario, true)
  //     .then(() => {
  //       setLoader(false);
  //     })
  //     .catch(() => {
  //       setLoader(false);
  //     });
  // };

  const formTwoValidation = () => {
    if (bodyData.facilityDetails?.[0]?.['facility']?.['type'] === 'farm') {
      let validateForm = true;
      if (
        !bodyData.facilityDetails?.[0]?.['outdoorAcreage'] ||
        bodyData.facilityDetails?.[0]?.['outdoorAcreage'] < 0
      ) {
        toast.error('Please add outdoor acreage. Minimum value should be 1', alertOptions);
        validateForm = false;
      } else if (!bodyData.facilityDetails?.[0]?.['noOfCycles']) {
        toast.error('Please select number of cycles/year', alertOptions);
        validateForm = false;
      } else if (!bodyData.facilityDetails?.[0]?.['soilHumidity']) {
        toast.error('Please select soil humidity condition', alertOptions);
        validateForm = false;
      } else if (!bodyData.facilityDetails?.[0]?.['cultivationProd']) {
        toast.error('Please select cultivation production', alertOptions);
        validateForm = false;
      }
      if (!validateForm) {
        setValue('2');
        setSubValue('11');
        return false;
      }
    }

    if (bodyData.facilityDetails.length === 1) {
      if (!withoutProcessing && addOrView === 'add') {
        handleWithoutProcessing(true);
        return false;
      }
    }

    if (bodyData.facilityDetails.length > 1) {
      if (
        bodyData.facilityDetails?.[0]?.['cultivationProd'] === 'dualPurpose' ||
        bodyData.facilityDetails?.[0]?.['cultivationProd'] === 'seedPurpose'
      ) {
        if (!checkCondition(bodyData.facilityDetails?.[1]?.['transDistanceSeed'])) {
          toast.error('Please add transportation seed distance', alertOptions);
          setValue('2');
          setSubValue('22');
          return false;
        }
      }
      if (
        bodyData.facilityDetails?.[0]?.['cultivationProd'] === 'dualPurpose' ||
        bodyData.facilityDetails?.[0]?.['cultivationProd'] === 'strawPurpose'
      ) {
        if (!checkCondition(bodyData.facilityDetails?.[1]?.['transDistanceStraw'])) {
          toast.error('Please add transportation straw distance', alertOptions);
          setValue('2');
          setSubValue('22');
          return false;
        }
      }
    }
    return true;
  };

  const facilityNext = async (row: any) => {
    if (addOrView !== 'view') {
      setFacilityData(row);
    }
    // if (currentSpeciality?.count >= 0) {
    //   if (await formTwoValidation()) {
    //     setFacilityData(row);
    //   }
    // } else {
    //   setFacilityData(row);
    // }
  };

  const submitAllTab = () => {
    if (addOrView === 'edit') {
      setLoader(true);
      updateData(bodyData._id, bodyData, MASTER_ENDPOINT.Scenario, true)
        .then((resp) => {
          setLoader(false);
          setEditOption(false);
          navigate(PrivateRoutes.DASHBOARD, { state: { id: resp?.id } });
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      bodyData['date'] = moment().format('YYYY/MM/DD');
      setLoader(true);
      addNewData(bodyData, MASTER_ENDPOINT.Scenario, '', true)
        .then((resp) => {
          setLoader(false);
          setEditOption(false);
          navigate(PrivateRoutes.DASHBOARD, { state: { id: resp?.id } });
          setRedirectId(resp?.id);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSingle = (key: string, value: string) => {
    setBodyData({ ...bodyData, [key]: value });
  };

  const setFacilityFunction = (key: string, value: string, id: string, type?: string) => {
    if (bodyData.facilityDetails && bodyData.facilityDetails.length) {
      const fIndex = bodyData.facilityDetails.findIndex((item: any) => item.facility._id === id);
      if (fIndex !== -1) {
        if (key === 'transVialorry' || key === 'transViatruck' || key === 'transViaCar') {
          let truckInt = bodyData.facilityDetails[fIndex]['transViatruck']
            ? parseInt(bodyData.facilityDetails[fIndex]['transViatruck'])
            : 0;
          let lorryInt = bodyData.facilityDetails[fIndex]['transVialorry']
            ? parseInt(bodyData.facilityDetails[fIndex]['transVialorry'])
            : 0;
          let carInt = bodyData.facilityDetails[fIndex]['transViaCar']
            ? parseInt(bodyData.facilityDetails[fIndex]['transViaCar'])
            : 0;
          if (key === 'transVialorry') {
            lorryInt = parseInt(value);
          } else if (key === 'transViatruck') {
            truckInt = parseInt(value);
          } else if (key === 'transViaCar') {
            carInt = parseInt(value);
          }
          const totalTransportDis = truckInt + lorryInt + carInt;
          bodyData.facilityDetails[fIndex]['totalTransportDistance'] = totalTransportDis;
        }
        bodyData.facilityDetails[fIndex][key] =
          type === 'number' ? (value ? parseInt(value) : null) : value;
        setBodyData({ ...bodyData, facilityDetails: bodyData.facilityDetails });
      }
    }
  };

  const setFacilityData = (row: any) => {
    const addRow = {
      facility: {
        organization: row.organization,
        name: row.name,
        type: row.type,
        createdBy: row.createdBy,
        _id: row._id,
      },
      outdoorAcreage: null,
      noOfCycles: null,
      soilHumidity: null,
      cultivationProd: null,
      transVialorry: null,
      transViatruck: null,
      transViaCar: null,
      totalTransportDistance: null,
      transDistanceSeed: null,
      transDistanceStraw: null,
      fiberTransport: null,
      hurdTransport: null,
      dustTransport: null,
      oilTransport: null,
      cakeTransport: null,
      hullTransport: null,
      dustType: null,
      burnWaste: null,
      burnedBioMass: null,
      transportToLandfill: null,
      lorryDetails: [],
      truckDetails: [],
      carDetails: [],
      fiberDetails: [],
      hurdDetails: [],
      dustDetails: [],
      oilDetails: [],
      cakeDetails: [],
      hullDetails: [],
    };
    const facilityObject = bodyData.facilityDetails;
    if (facilityObject && facilityObject.length) {
      const fIndexfacility = facilityObject.findIndex(
        (item: any) => item.facility.type === row.type,
      );
      if (fIndexfacility !== -1) {
        toast.error(
          row?.type === 'farm' ? 'Farm process already added' : 'Processing plant already added',
          alertOptions,
        );
      } else {
        facilityObject.push(addRow);
      }
    } else {
      if (row?.type === 'farm') {
        facilityObject.push(addRow);
      } else {
        toast.error('Please add farm process', alertOptions);
        setSubValue('11');
      }
    }
    setBodyData({ ...bodyData });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString());
  };

  const [subvalue, setSubValue] = React.useState('11');

  useEffect(() => {
    if (addOrView === 'add' && subvalue === '22' && bodyData.facilityDetails.length === 1) {
      if (processList.length) {
        setFacilityData(processList[0]);
      }
    }
  }, [subvalue]);

  const handlesubTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSubValue(newValue);
  };

  const [numberDetail, setNumberDetail] = React.useState({
    open: false,
    mainKey: '',
    totalKey: '',
    facilityIndex: 0,
    heading: '',
  });
  const [numberArr, setNumberArr] = React.useState<any>([]);
  const handleTruckClose = () => {
    setNumberDetail({
      open: false,
      mainKey: '',
      totalKey: '',
      facilityIndex: 0,
      heading: '',
    });
    setNumberArr([]);
  };

  const onChangeNumber = (key1: string, key2: string, index: number, heading: string) => {
    if (addOrView !== 'view') {
      setNumberArr(
        bodyData.facilityDetails[index][key2].length
          ? [...bodyData.facilityDetails[index][key2]]
          : [
              {
                vendor: '',
                addressData: [
                  {
                    distance: null,
                    fromAddress: null,
                    fromLat: null,
                    fromLng: null,
                    toAddress: null,
                    toLat: null,
                    toLng: null,
                  },
                ],
                // distance: null,
              },
            ],
      );
      setNumberDetail({
        open: true,
        mainKey: key2,
        totalKey: key1,
        facilityIndex: index,
        heading: heading,
      });
    } else {
      if (
        bodyData.facilityDetails?.[index]?.[key2] &&
        bodyData.facilityDetails?.[index]?.[key2].length
      ) {
        setNumberDetail({
          open: true,
          mainKey: key2,
          totalKey: key1,
          facilityIndex: index,
          heading: heading,
        });
        setNumberArr(bodyData.facilityDetails?.[index]?.[key2]);
      }
    }
  };

  const submitNumber = () => {
    let totalVal: any = '';
    const orgNumberArr = numberArr.filter((item: any) => {
      item.addressData.map((row: any) => {
        if (row.distance) {
          totalVal =
            (totalVal ? parseFloat(totalVal) : 0) + (row.distance ? parseFloat(row.distance) : 0);
        }
      });
      return true;
    });
    bodyData.facilityDetails[numberDetail.facilityIndex][numberDetail.mainKey] = orgNumberArr;
    bodyData.facilityDetails[numberDetail.facilityIndex][numberDetail.totalKey] = numberArr.length
      ? totalVal
        ? parseFloat(totalVal)
        : null
      : null;
    setBodyData({ ...bodyData });
    handleTruckClose();
  };

  const [withoutProcessingOpen, setWithoutProcessingOpen] = React.useState(false);
  const [withoutProcessing, setWithoutProcessing] = React.useState(false);
  const handleWithoutProcessing = (opn: boolean) => {
    setWithoutProcessingOpen(opn);
  };

  return (
    <Grid container>
      <Loader openLoader={loader} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='d-flex-a flex-sb bottom-border pb-24'>
          <Typography component={'div'} className='d-flex-a'>
            {' '}
            <Typography className='commontitle'>
              {addOrView === 'add' ? 'Add' : addOrView === 'edit' ? 'Edit' : 'View'} scenario{' '}
            </Typography>{' '}
            <Typography className='commontitle pl-4'>
              {' '}
              <span className='commontitle'>{'>'} </span>Scenario Info
              {value === '2' && (
                <>
                  {' '}
                  <span className='commontitle'>{'>'} </span>Facility Details
                </>
              )}
              {value === '3' && (
                <>
                  {' '}
                  <span className='commontitle'>{'>'} </span>Applicable Parameters
                </>
              )}
            </Typography>{' '}
          </Typography>
          <Typography component={'div'} className='d-flex-a'>
            {value === '2' && false && (
              <Button
                onClick={handleOpen}
                className='save-btn'
                startIcon={<AddIcon sx={{ fill: '#ffffff' }} />}>
                Add Facility
              </Button>
            )}
            <Modal
              className='addfacilitymodal'
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'>
              <Box sx={style}>
                <Typography component={'div'} sx={{ position: 'relative' }}>
                  <IconButton onClick={handleClose} className='close-btn'>
                    <img src={Close} alt='close' />
                  </IconButton>
                  <Typography component={'div'} className='modal-normal-title'>
                    Add facility
                  </Typography>
                  <form className='custom-form' name={'facilityForm'}>
                    <Grid container columnSpacing={4} rowSpacing={3.5}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Controller
                          control={control}
                          defaultValue=''
                          name='name'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                label='Facility Name *'
                                className='custom-input'
                                onChange={onChange}
                                value={value}
                                name={name}
                                error={formState.errors.name?.message}
                              />
                            );
                          }}
                        />
                      </Grid>{' '}
                      <Grid item md={6} sm={12} xs={12}>
                        <FormControl fullWidth className='custom-input'>
                          <InputLabel id='demo-simple-select-label'>Facility Type *</InputLabel>
                          <Controller
                            control={control}
                            defaultValue=''
                            name='type'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                  error={formState.errors.type?.message}
                                  options={FacilityType}
                                  labelKey='label'
                                  valueKey='value'
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>{' '}
                      <Grid item md={12} sm={12} xs={12}>
                        <Controller
                          control={control}
                          defaultValue=''
                          name='address'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                label='Address'
                                className='custom-input'
                                isTextArea={true}
                                onChange={onChange}
                                value={value}
                                name={name}
                                minRows={4}
                                maxRows={4}
                                error={formState.errors.address?.message}
                              />
                            );
                          }}
                        />
                      </Grid>{' '}
                    </Grid>
                  </form>
                  <Typography component={'div'} className='in-flex flex-end mt-24'>
                    <Button className='cancel-btn btn-2r' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      className='save-btn'
                      form={'facilityForm'}
                      onClick={handleSubmit(submitForm)}>
                      Save
                    </Button>
                  </Typography>
                </Typography>
              </Box>
            </Modal>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='form-box custom-tab' sx={{ padding: '24px 38px' }}>
          <TabContext value={value}>
            <Box className='bottom-border'>
              <Typography component={'div'} className='d-flex-a flex-sb'>
                <TabList
                  className='o2'
                  variant='scrollable'
                  scrollButtons='auto'
                  aria-label='lab API tabs example'
                  onChange={handleChange}>
                  <Tab label='Scenario Info' value='1' />
                  <Tab label='Facility details' value='2' />
                  <Tab label='Applicable Parameters' value='3' />
                </TabList>
                {value === '3' && addOrView !== 'view' && (
                  <Typography component={'div'} className='o1'>
                    <Typography component={'div'} className=' in-flex'>
                      <Button
                        className='save-btn'
                        onClick={() => {
                          // if (addOrView !== 'add') {
                          //   if (editOption) {
                          //     editStandardFunctions();
                          //   }
                          // }
                          setEditOption(!editOption);
                        }}
                        sx={{
                          fontSize: '12px !important',
                          paddingTop: '6px !important',
                          paddingBottom: '6px !important',
                        }}>
                        {editOption ? 'Save' : 'Click to edit'}
                      </Button>
                    </Typography>
                  </Typography>
                )}
              </Typography>
            </Box>
            <TabPanel value='1'>
              {' '}
              <Grid container>
                <Grid item md={8} sm={12} xs={12}>
                  <form className='custom-form'>
                    <Grid container columnSpacing={4} rowSpacing={3.5}>
                      <Grid item md={6} sm={12} xs={12}>
                        <CustomInput
                          label='Name*'
                          onChange={(e) => handleSingle('name', e.target.value)}
                          className='custom-input'
                          value={bodyData.name}
                          disabled={addOrView === 'view'}
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <CustomInput
                          disabled={true}
                          label='Created date*'
                          className='custom-input'
                          value={moment(bodyData.createdAt).format('MM/DD/YYYY')}
                        />
                      </Grid>{' '}
                      <Grid item md={12} sm={12} xs={12}>
                        <CustomInput
                          disabled={addOrView === 'view'}
                          onChange={(e) => handleSingle('comments', e.target.value)}
                          label='Comments'
                          isTextArea={true}
                          minRows={4}
                          className='custom-input'
                          value={bodyData.comments}
                        />
                      </Grid>{' '}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value='2'>
              <Typography component={'div'} className='custom-sub-tab'>
                <TabContext value={subvalue}>
                  <Box className='bottom-border'>
                    <TabList
                      variant='scrollable'
                      scrollButtons='auto'
                      aria-label='lab API tabs example'
                      onChange={handlesubTabChange}>
                      <Tab label='farm' value='11' />
                      <Tab label='Processing' value='22' />
                    </TabList>
                  </Box>
                  <TabPanel value='11'>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Grid container columnSpacing={5} rowSpacing={2.5}>
                          <Grid item md={4} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-table sticky-table'>
                              <TableContainer component={Paper} sx={{ maxHeight: '35vh' }}>
                                <Table stickyHeader aria-label='simple table'>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className='w-40'>
                                        <Typography component={'div'} className='d-flex-a'>
                                          {/* <Typography component={'div'} className='arrow-box'>
                                      <img src={ArrowDown} alt='arrow' className='d-arrow' />
                                      <img src={ArrowDown} alt='arrow' className='u-arrow' />
                                    </Typography> */}
                                          <Typography className='th-title'>Farm</Typography>
                                        </Typography>
                                      </TableCell>
                                      {/* <TableCell className='w-20' /> */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {farmList.length ? (
                                      farmList.map((row: any) => (
                                        <TableRow
                                          key={row.id}
                                          className={
                                            bodyData?.facilityDetails?.[0]?.facility?._id ===
                                            row._id
                                              ? 'active-row'
                                              : 'false'
                                          }
                                          // onClick={() => facilityNext(row)}
                                        >
                                          <TableCell>
                                            <Link
                                              style={{ textTransform: 'capitalize' }}
                                              href='javascript:void(0);'
                                              color='inherit'
                                              underline='none'>
                                              {row.name}
                                            </Link>
                                          </TableCell>
                                          {false && (
                                            <TableCell>
                                              <Typography component={'div'} className='d-flex-a'>
                                                <img
                                                  src={Delete}
                                                  alt='delete'
                                                  className='mr-24'
                                                  onClick={handledeleteOpen}
                                                />
                                              </Typography>
                                              <Modal
                                                className='deletemodal'
                                                open={opendelete}
                                                onClose={handledeleteClose}
                                                aria-labelledby='modal-modal-title'
                                                aria-describedby='modal-modal-description'>
                                                <Box sx={style}>
                                                  <Typography
                                                    component={'div'}
                                                    className='d-flex-ja'
                                                    flexDirection='column'
                                                    sx={{ position: 'relative' }}>
                                                    <img
                                                      src={Close}
                                                      alt='close'
                                                      className='close-btn'
                                                    />
                                                    <Typography
                                                      component={'div'}
                                                      className='modal-normal-title'
                                                      sx={{ marginTop: '60px' }}>
                                                      Delete facility
                                                    </Typography>
                                                    <Typography className='delete-dis'>
                                                      Do you really want to delete these facility?
                                                      This process cannot be undone.
                                                    </Typography>
                                                    <Typography
                                                      component={'div'}
                                                      className='in-flex flex-end mt-24'
                                                      sx={{ marginBottom: '36px' }}>
                                                      <Button className='cancel-btn btn-2r'>
                                                        Cancel
                                                      </Button>
                                                      <Button className='save-btn'>Save</Button>
                                                    </Typography>
                                                  </Typography>
                                                </Box>
                                              </Modal>
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow>
                                        <TableCell>No Records Found</TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Typography>
                          </Grid>
                          {bodyData?.facilityDetails?.[0] ? (
                            <Grid item md={8} sm={12} xs={12}>
                              <form className='custom-form'>
                                <Grid container rowSpacing={2.5}>
                                  <Grid item md={5.5} sm={12} xs={12}>
                                    <Grid container rowSpacing={2.5}>
                                      <Grid item md={12} sm={12} xs={12}>
                                        <CustomInput
                                          label='Outdoor Acreage *'
                                          className='custom-input'
                                          type={'number'}
                                          value={bodyData.facilityDetails?.[0]?.['outdoorAcreage']}
                                          onChange={(e) =>
                                            setFacilityFunction(
                                              'outdoorAcreage',
                                              e.target.value,
                                              bodyData.facilityDetails?.[0]?.facility?._id,
                                              'number',
                                            )
                                          }
                                          disabled={addOrView === 'view'}
                                        />
                                      </Grid>
                                      <Grid item md={12} sm={12} xs={12}>
                                        <FormControl fullWidth className='custom-input'>
                                          <InputLabel id='demo-simple-select-label'>
                                            Number Cycles/Year *
                                          </InputLabel>
                                          <CustomSelect
                                            value={bodyData.facilityDetails?.[0]?.['noOfCycles']}
                                            onChange={(e) =>
                                              setFacilityFunction(
                                                'noOfCycles',
                                                e.target.value,
                                                bodyData.facilityDetails?.[0]?.facility?._id,
                                              )
                                            }
                                            options={[
                                              { label: '1', value: 1 },
                                              { label: '2', value: 2 },
                                              { label: '3', value: 3 },
                                            ]}
                                            labelKey='label'
                                            valueKey='value'
                                            disabled={addOrView === 'view'}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item md={12} sm={12} xs={12}>
                                        <FormControl fullWidth className='custom-input'>
                                          <InputLabel id='demo-simple-select-label'>
                                            Soil Humidity Condition *
                                          </InputLabel>
                                          <CustomSelect
                                            value={bodyData.facilityDetails?.[0]?.['soilHumidity']}
                                            onChange={(e) =>
                                              setFacilityFunction(
                                                'soilHumidity',
                                                e.target.value,
                                                bodyData.facilityDetails?.[0]?.facility?._id,
                                              )
                                            }
                                            options={SoilHumidity}
                                            labelKey='label'
                                            valueKey='value'
                                            disabled={addOrView === 'view'}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item md={12} sm={12} xs={12}>
                                        <FormControl fullWidth className='custom-input'>
                                          <InputLabel id='demo-simple-select-label'>
                                            Cultivation Production *
                                          </InputLabel>
                                          <CustomSelect
                                            value={
                                              bodyData.facilityDetails?.[0]?.['cultivationProd']
                                            }
                                            onChange={(e) => {
                                              setFacilityFunction(
                                                'cultivationProd',
                                                e.target.value,
                                                bodyData.facilityDetails?.[0]?.facility?._id,
                                              );
                                            }}
                                            options={FacilityModelType}
                                            labelKey='label'
                                            valueKey='value'
                                            disabled={addOrView !== 'add'}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item md={1} sm={12} xs={12} />
                                  <Grid item md={5.5} sm={12} xs={12}>
                                    <Grid item md={12} sm={12} xs={12}>
                                      <Typography
                                        component={'div'}
                                        sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Pre-Cultivation
                                      </Typography>
                                      <Typography
                                        component={'div'}
                                        sx={{ paddingTop: '10px', fontSize: '13px' }}>
                                        Transportation Distance (in Miles)
                                      </Typography>
                                      <Typography component={'div'} sx={{ paddingTop: '10px' }} />
                                      <Typography
                                        sx={{ cursor: 'pointer' }}
                                        component={'div'}
                                        onClick={() =>
                                          onChangeNumber(
                                            'transVialorry',
                                            'lorryDetails',
                                            0,
                                            'By Dump Truck',
                                          )
                                        }>
                                        <CustomInput
                                          label='Dump Truck'
                                          className='custom-input'
                                          // type={'number'}
                                          value={
                                            bodyData.facilityDetails?.[0]?.['transVialorry']
                                              ? bodyData.facilityDetails?.[0]?.['transVialorry']
                                              : bodyData.facilityDetails?.[0]?.['transVialorry'] ===
                                                0
                                              ? 0
                                              : ''
                                          }
                                          extraStyle1={{
                                            pointerEvents: 'none !important',
                                          }}
                                          // onChange={(e) =>
                                          //   setFacilityFunction(
                                          //     'transVialorry',
                                          //     e.target.value,
                                          //     bodyData.facilityDetails?.[0]?.facility?._id,
                                          //     'number',
                                          //   )
                                          // }
                                          // disabled={addOrView !== 'add'}
                                          inputProps={{
                                            shrink: true,
                                            endAdornment: (
                                              <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'>
                                                <img src={AddIconNumber} />
                                              </IconButton>
                                            ),
                                          }}
                                        />
                                      </Typography>
                                      <Typography component={'div'} sx={{ paddingTop: '20px' }} />
                                      <Typography
                                        sx={{ cursor: 'pointer' }}
                                        component={'div'}
                                        onClick={() =>
                                          onChangeNumber(
                                            'transViatruck',
                                            'truckDetails',
                                            0,
                                            'By Semi Truck',
                                          )
                                        }>
                                        <CustomInput
                                          label='Semi Truck'
                                          className='custom-input'
                                          // type={'number'}
                                          extraStyle={{
                                            '& input': {
                                              pointerEvents: 'none !important',
                                            },
                                          }}
                                          value={
                                            bodyData.facilityDetails?.[0]?.['transViatruck']
                                              ? bodyData.facilityDetails?.[0]?.['transViatruck']
                                              : bodyData.facilityDetails?.[0]?.['transViatruck'] ===
                                                0
                                              ? 0
                                              : ''
                                          }
                                          // onChange={(e) =>
                                          //   setFacilityFunction(
                                          //     'transViatruck',
                                          //     e.target.value,
                                          //     bodyData.facilityDetails?.[0]?.facility?._id,
                                          //     'number',
                                          //   )
                                          // }
                                          inputProps={{
                                            shrink: true,
                                            endAdornment: (
                                              <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'>
                                                <img src={AddIconNumber} />
                                              </IconButton>
                                            ),
                                          }}
                                          // disabled={addOrView !== 'add'}
                                        />
                                      </Typography>
                                      <Typography component={'div'} sx={{ paddingTop: '20px' }} />
                                      <Typography
                                        sx={{ cursor: 'pointer' }}
                                        component={'div'}
                                        onClick={() =>
                                          onChangeNumber(
                                            'transViaCar',
                                            'carDetails',
                                            0,
                                            'By Reefer Truck',
                                          )
                                        }>
                                        <CustomInput
                                          label='Reefer Truck'
                                          className='custom-input'
                                          // type={'number'}
                                          extraStyle={{
                                            '& input': {
                                              pointerEvents: 'none !important',
                                            },
                                          }}
                                          value={
                                            bodyData.facilityDetails?.[0]?.['transViaCar']
                                              ? bodyData.facilityDetails?.[0]?.['transViaCar']
                                              : bodyData.facilityDetails?.[0]?.['transViaCar'] === 0
                                              ? 0
                                              : ''
                                          }
                                          // onChange={(e) =>
                                          //   setFacilityFunction(
                                          //     'transViaCar',
                                          //     e.target.value,
                                          //     bodyData.facilityDetails?.[0]?.facility?._id,
                                          //     'number',
                                          //   )
                                          // }
                                          inputProps={{
                                            shrink: true,
                                            endAdornment: (
                                              <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'>
                                                <img src={AddIconNumber} />
                                              </IconButton>
                                            ),
                                          }}
                                          // disabled={addOrView !== 'add'}
                                        />
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </form>
                            </Grid>
                          ) : (
                            <Typography />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value='22'>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Grid container columnSpacing={5} rowSpacing={2.5}>
                          <Grid item md={4} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-table sticky-table'>
                              <TableContainer component={Paper} sx={{ maxHeight: '35vh' }}>
                                <Table stickyHeader aria-label='simple table'>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className='w-40'>
                                        <Typography component={'div'} className='d-flex-a'>
                                          {/* <Typography component={'div'} className='arrow-box'>
                                      <img src={ArrowDown} alt='arrow' className='d-arrow' />
                                      <img src={ArrowDown} alt='arrow' className='u-arrow' />
                                    </Typography> */}
                                          <Typography className='th-title'>
                                            Processing Plant
                                          </Typography>
                                        </Typography>
                                      </TableCell>
                                      {/* <TableCell className='w-20' /> */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {processList.length ? (
                                      processList.map((row: any) => (
                                        <TableRow
                                          key={row.id}
                                          className={
                                            bodyData?.facilityDetails?.[1]?.facility?._id ===
                                            row._id
                                              ? 'active-row'
                                              : 'false'
                                          }
                                          // onClick={() => facilityNext(row)}
                                        >
                                          <TableCell>
                                            <Link
                                              sx={{ textTransform: 'capitalize' }}
                                              href='javascript:void(0);'
                                              color='inherit'
                                              underline='none'>
                                              {row.name}
                                            </Link>
                                          </TableCell>
                                          {false && (
                                            <TableCell>
                                              <Typography component={'div'} className='d-flex-a'>
                                                <img
                                                  src={Delete}
                                                  alt='delete'
                                                  className='mr-24'
                                                  onClick={handledeleteOpen}
                                                />
                                              </Typography>
                                              <Modal
                                                className='deletemodal'
                                                open={opendelete}
                                                onClose={handledeleteClose}
                                                aria-labelledby='modal-modal-title'
                                                aria-describedby='modal-modal-description'>
                                                <Box sx={style}>
                                                  <Typography
                                                    component={'div'}
                                                    className='d-flex-ja'
                                                    flexDirection='column'
                                                    sx={{ position: 'relative' }}>
                                                    <img
                                                      src={Close}
                                                      alt='close'
                                                      className='close-btn'
                                                    />
                                                    <Typography
                                                      component={'div'}
                                                      className='modal-normal-title'
                                                      sx={{ marginTop: '60px' }}>
                                                      Delete facility
                                                    </Typography>
                                                    <Typography className='delete-dis'>
                                                      Do you really want to delete these facility?
                                                      This process cannot be undone.
                                                    </Typography>
                                                    <Typography
                                                      component={'div'}
                                                      className='in-flex flex-end mt-24'
                                                      sx={{ marginBottom: '36px' }}>
                                                      <Button className='cancel-btn btn-2r'>
                                                        Cancel
                                                      </Button>
                                                      <Button className='save-btn'>Save</Button>
                                                    </Typography>
                                                  </Typography>
                                                </Box>
                                              </Modal>
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow>
                                        <TableCell>No Records Found</TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Typography>
                          </Grid>
                          {bodyData?.facilityDetails?.[1] ? (
                            <Grid item md={8} sm={12} xs={12}>
                              <form className='custom-form'>
                                {bodyData.facilityDetails && bodyData.facilityDetails.length ? (
                                  <Grid container rowSpacing={2.5}>
                                    <Grid item md={5.5} sm={12} xs={12}>
                                      <Grid container rowSpacing={2.5}>
                                        {(bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                          'dualPurpose' ||
                                          bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                            'seedPurpose') && (
                                          <Grid item md={12} sm={12} xs={12}>
                                            <CustomInput
                                              label='Transportation Seed Distance *'
                                              className='custom-input'
                                              type={'number'}
                                              value={
                                                bodyData.facilityDetails?.[1]?.['transDistanceSeed']
                                                  ? bodyData.facilityDetails?.[1]?.[
                                                      'transDistanceSeed'
                                                    ]
                                                  : ''
                                              }
                                              onChange={(e) =>
                                                setFacilityFunction(
                                                  'transDistanceSeed',
                                                  e.target.value,
                                                  bodyData.facilityDetails?.[1]?.facility?._id,
                                                  'number',
                                                )
                                              }
                                              disabled={addOrView === 'view'}
                                            />
                                          </Grid>
                                        )}
                                        {(bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                          'dualPurpose' ||
                                          bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                            'strawPurpose') && (
                                          <Grid item md={12} sm={12} xs={12}>
                                            <CustomInput
                                              label='Transportation Straw Distance *'
                                              className='custom-input'
                                              type={'number'}
                                              value={
                                                bodyData.facilityDetails?.[1]?.[
                                                  'transDistanceStraw'
                                                ]
                                                  ? bodyData.facilityDetails?.[1]?.[
                                                      'transDistanceStraw'
                                                    ]
                                                  : ''
                                              }
                                              onChange={(e) =>
                                                setFacilityFunction(
                                                  'transDistanceStraw',
                                                  e.target.value,
                                                  bodyData.facilityDetails?.[1]?.facility?._id,
                                                  'number',
                                                )
                                              }
                                              disabled={addOrView === 'view'}
                                            />
                                          </Grid>
                                        )}
                                        <Grid item md={12} sm={12} xs={12}>
                                          <Typography
                                            component={'div'}
                                            sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            Post-Process
                                          </Typography>
                                          <Typography
                                            component={'div'}
                                            sx={{ paddingTop: '10px', fontSize: '13px' }}>
                                            Transportation Distance (in Miles)
                                          </Typography>
                                        </Grid>
                                        {(bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                          'dualPurpose' ||
                                          bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                            'strawPurpose') && (
                                          <>
                                            <Grid item md={12} sm={12} xs={12}>
                                              <Typography
                                                sx={{ cursor: 'pointer' }}
                                                component={'div'}
                                                onClick={() =>
                                                  onChangeNumber(
                                                    'fiberTransport',
                                                    'fiberDetails',
                                                    1,
                                                    'For Fiber',
                                                  )
                                                }>
                                                <CustomInput
                                                  label='Fiber'
                                                  className='custom-input'
                                                  // type={'number'}
                                                  extraStyle={{
                                                    '& input': {
                                                      pointerEvents: 'none !important',
                                                    },
                                                  }}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.[
                                                      'fiberTransport'
                                                    ]
                                                      ? bodyData.facilityDetails?.[1]?.[
                                                          'fiberTransport'
                                                        ]
                                                      : bodyData.facilityDetails?.[1]?.[
                                                          'fiberTransport'
                                                        ] === 0
                                                      ? 0
                                                      : ''
                                                  }
                                                  // onChange={(e) =>
                                                  //   setFacilityFunction(
                                                  //     'fiberTransport',
                                                  //     e.target.value,
                                                  //     bodyData.facilityDetails?.[1]?.facility?._id,
                                                  //     'number',
                                                  //   )
                                                  // }
                                                  inputProps={{
                                                    shrink: true,
                                                    endAdornment: (
                                                      <IconButton
                                                        aria-label='toggle password visibility'
                                                        edge='end'>
                                                        <img src={AddIconNumber} />
                                                      </IconButton>
                                                    ),
                                                  }}
                                                  // disabled={addOrView !== 'add'}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                              <Typography
                                                sx={{ cursor: 'pointer' }}
                                                component={'div'}
                                                onClick={() =>
                                                  onChangeNumber(
                                                    'hurdTransport',
                                                    'hurdDetails',
                                                    1,
                                                    'For Hurd',
                                                  )
                                                }>
                                                <CustomInput
                                                  label='Hurd'
                                                  className='custom-input'
                                                  // type={'number'}
                                                  extraStyle={{
                                                    '& input': {
                                                      pointerEvents: 'none !important',
                                                    },
                                                  }}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.['hurdTransport']
                                                      ? bodyData.facilityDetails?.[1]?.[
                                                          'hurdTransport'
                                                        ]
                                                      : bodyData.facilityDetails?.[1]?.[
                                                          'hurdTransport'
                                                        ] === 0
                                                      ? 0
                                                      : ''
                                                  }
                                                  // onChange={(e) =>
                                                  //   setFacilityFunction(
                                                  //     'hurdTransport',
                                                  //     e.target.value,
                                                  //     bodyData.facilityDetails?.[1]?.facility?._id,
                                                  //     'number',
                                                  //   )
                                                  // }
                                                  inputProps={{
                                                    shrink: true,
                                                    endAdornment: (
                                                      <IconButton
                                                        aria-label='toggle password visibility'
                                                        edge='end'>
                                                        <img src={AddIconNumber} />
                                                      </IconButton>
                                                    ),
                                                  }}
                                                  // disabled={addOrView !== 'add'}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                              <Typography
                                                sx={{ cursor: 'pointer' }}
                                                component={'div'}
                                                onClick={() =>
                                                  onChangeNumber(
                                                    'dustTransport',
                                                    'dustDetails',
                                                    1,
                                                    'For Dust',
                                                  )
                                                }>
                                                <CustomInput
                                                  label='Dust'
                                                  className='custom-input'
                                                  // type={'number'}
                                                  extraStyle={{
                                                    '& input': {
                                                      pointerEvents: 'none !important',
                                                    },
                                                  }}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.['dustTransport']
                                                      ? bodyData.facilityDetails?.[1]?.[
                                                          'dustTransport'
                                                        ]
                                                      : bodyData.facilityDetails?.[1]?.[
                                                          'dustTransport'
                                                        ] === 0
                                                      ? 0
                                                      : ''
                                                  }
                                                  // onChange={(e) =>
                                                  //   setFacilityFunction(
                                                  //     'dustTransport',
                                                  //     e.target.value,
                                                  //     bodyData.facilityDetails?.[1]?.facility?._id,
                                                  //     'number',
                                                  //   )
                                                  // }
                                                  inputProps={{
                                                    shrink: true,
                                                    endAdornment: (
                                                      <IconButton
                                                        aria-label='toggle password visibility'
                                                        edge='end'>
                                                        <img src={AddIconNumber} />
                                                      </IconButton>
                                                    ),
                                                  }}
                                                  // disabled={addOrView !== 'add'}
                                                />
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                        {(bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                          'dualPurpose' ||
                                          bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                            'seedPurpose') && (
                                          <>
                                            <Grid item md={12} sm={12} xs={12}>
                                              <Typography
                                                sx={{ cursor: 'pointer' }}
                                                component={'div'}
                                                onClick={() =>
                                                  onChangeNumber(
                                                    'oilTransport',
                                                    'oilDetails',
                                                    1,
                                                    'For Oil',
                                                  )
                                                }>
                                                <CustomInput
                                                  label='Oil'
                                                  className='custom-input'
                                                  // type={'number'}
                                                  extraStyle={{
                                                    '& input': {
                                                      pointerEvents: 'none !important',
                                                    },
                                                  }}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.['oilTransport']
                                                      ? bodyData.facilityDetails?.[1]?.[
                                                          'oilTransport'
                                                        ]
                                                      : bodyData.facilityDetails?.[1]?.[
                                                          'oilTransport'
                                                        ] === 0
                                                      ? 0
                                                      : ''
                                                  }
                                                  // onChange={(e) =>
                                                  //   setFacilityFunction(
                                                  //     'oilTransport',
                                                  //     e.target.value,
                                                  //     bodyData.facilityDetails?.[1]?.facility?._id,
                                                  //     'number',
                                                  //   )
                                                  // }
                                                  inputProps={{
                                                    shrink: true,
                                                    endAdornment: (
                                                      <IconButton
                                                        aria-label='toggle password visibility'
                                                        edge='end'>
                                                        <img src={AddIconNumber} />
                                                      </IconButton>
                                                    ),
                                                  }}
                                                  // disabled={addOrView !== 'add'}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                              <Typography
                                                sx={{ cursor: 'pointer' }}
                                                component={'div'}
                                                onClick={() =>
                                                  onChangeNumber(
                                                    'cakeTransport',
                                                    'cakeDetails',
                                                    1,
                                                    'For Cake',
                                                  )
                                                }>
                                                <CustomInput
                                                  label='Cake'
                                                  className='custom-input'
                                                  // type={'number'}
                                                  extraStyle={{
                                                    '& input': {
                                                      pointerEvents: 'none !important',
                                                    },
                                                  }}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.['cakeTransport']
                                                      ? bodyData.facilityDetails?.[1]?.[
                                                          'cakeTransport'
                                                        ]
                                                      : bodyData.facilityDetails?.[1]?.[
                                                          'cakeTransport'
                                                        ] === 0
                                                      ? 0
                                                      : ''
                                                  }
                                                  // onChange={(e) =>
                                                  //   setFacilityFunction(
                                                  //     'cakeTransport',
                                                  //     e.target.value,
                                                  //     bodyData.facilityDetails?.[1]?.facility?._id,
                                                  //     'number',
                                                  //   )
                                                  // }
                                                  inputProps={{
                                                    shrink: true,
                                                    endAdornment: (
                                                      <IconButton
                                                        aria-label='toggle password visibility'
                                                        edge='end'>
                                                        <img src={AddIconNumber} />
                                                      </IconButton>
                                                    ),
                                                  }}
                                                  // disabled={addOrView !== 'add'}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                              <Typography
                                                sx={{ cursor: 'pointer' }}
                                                component={'div'}
                                                onClick={() =>
                                                  onChangeNumber(
                                                    'hullTransport',
                                                    'hullDetails',
                                                    1,
                                                    'For Hull',
                                                  )
                                                }>
                                                <CustomInput
                                                  label='Hull'
                                                  className='custom-input'
                                                  // type={'number'}
                                                  extraStyle={{
                                                    '& input': {
                                                      pointerEvents: 'none !important',
                                                    },
                                                  }}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.['hullTransport']
                                                      ? bodyData.facilityDetails?.[1]?.[
                                                          'hullTransport'
                                                        ]
                                                      : bodyData.facilityDetails?.[1]?.[
                                                          'hullTransport'
                                                        ] === 0
                                                      ? 0
                                                      : ''
                                                  }
                                                  // onChange={(e) =>
                                                  //   setFacilityFunction(
                                                  //     'hullTransport',
                                                  //     e.target.value,
                                                  //     bodyData.facilityDetails?.[1]?.facility?._id,
                                                  //     'number',
                                                  //   )
                                                  // }
                                                  inputProps={{
                                                    shrink: true,
                                                    endAdornment: (
                                                      <IconButton
                                                        aria-label='toggle password visibility'
                                                        edge='end'>
                                                        <img src={AddIconNumber} />
                                                      </IconButton>
                                                    ),
                                                  }}
                                                  // disabled={addOrView !== 'add'}
                                                />
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid item md={1} sm={12} xs={12} />
                                    <Grid item md={5.5} sm={12} xs={12}>
                                      <Grid container rowSpacing={2.5}>
                                        <Grid item md={12} sm={12} xs={12}>
                                          <Typography
                                            component={'div'}
                                            sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                                            Waste Treatment
                                          </Typography>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                          <FormControl fullWidth className='custom-input'>
                                            <InputLabel id='demo-simple-select-label'>
                                              Dust Utilization
                                            </InputLabel>
                                            <CustomSelect
                                              value={bodyData.facilityDetails?.[1]?.['dustType']}
                                              onChange={(e) => {
                                                setFacilityFunction(
                                                  'dustType',
                                                  e.target.value,
                                                  bodyData.facilityDetails?.[1]?.facility?._id,
                                                );
                                              }}
                                              options={WasteTreatment}
                                              labelKey='label'
                                              valueKey='value'
                                              disabled={addOrView === 'view'}
                                            />
                                          </FormControl>
                                        </Grid>
                                        {bodyData.facilityDetails?.[1]?.['dustType'] ===
                                          'waste' && (
                                          <>
                                            <Grid item md={12} sm={12} xs={12}>
                                              <FormControl fullWidth className='custom-input'>
                                                <InputLabel id='demo-simple-select-label'>
                                                  Do You Burn Your Waste On-Site?
                                                </InputLabel>
                                                <CustomSelect
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.['burnWaste']
                                                  }
                                                  onChange={(e) =>
                                                    setFacilityFunction(
                                                      'burnWaste',
                                                      e.target.value,
                                                      bodyData.facilityDetails?.[1]?.facility?._id,
                                                    )
                                                  }
                                                  options={YesOrNoOption}
                                                  labelKey='label'
                                                  valueKey='value'
                                                  disabled={addOrView === 'view'}
                                                />
                                              </FormControl>
                                            </Grid>
                                            {bodyData.facilityDetails?.[1]?.['burnWaste'] ===
                                              'yes' && (
                                              <Grid item md={12} sm={12} xs={12}>
                                                <CustomInput
                                                  label='Amount (Lbs) Of Burned Biomass Per Cycle'
                                                  className='custom-input'
                                                  type={'number'}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.['burnedBioMass']
                                                  }
                                                  onChange={(e) =>
                                                    setFacilityFunction(
                                                      'burnedBioMass',
                                                      e.target.value,
                                                      bodyData.facilityDetails?.[1]?.facility?._id,
                                                      'number',
                                                    )
                                                  }
                                                  disabled={addOrView === 'view'}
                                                />
                                              </Grid>
                                            )}
                                            {bodyData.facilityDetails?.[1]?.['burnWaste'] ===
                                              'no' && (
                                              <Grid item md={12} sm={12} xs={12}>
                                                <CustomInput
                                                  label='Transport To Landfill'
                                                  className='custom-input'
                                                  type={'number'}
                                                  value={
                                                    bodyData.facilityDetails?.[1]?.[
                                                      'transportToLandfill'
                                                    ]
                                                  }
                                                  onChange={(e) =>
                                                    setFacilityFunction(
                                                      'transportToLandfill',
                                                      e.target.value,
                                                      bodyData.facilityDetails?.[1]?.facility?._id,
                                                      'number',
                                                    )
                                                  }
                                                  disabled={addOrView === 'view'}
                                                />
                                              </Grid>
                                            )}
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Typography />
                                )}
                              </form>
                            </Grid>
                          ) : (
                            <Typography />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Typography>
            </TabPanel>
            <TabPanel value='3'>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-table'>
                    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 442px)' }}>
                      <Table stickyHeader sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography component={'div'} className='d-flex-a'>
                                {/* <Typography component={'div'} className='arrow-box'>
                                  <img src={ArrowDown} alt='arrow' className='d-arrow' />
                                  <img src={ArrowDown} alt='arrow' className='u-arrow' />
                                </Typography> */}
                                <Typography className='th-title'>Category</Typography>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography component={'div'} className='d-flex-a'>
                                {/* <Typography component={'div'} className='arrow-box'>
                                  <img src={ArrowDown} alt='arrow' className='d-arrow' />
                                  <img src={ArrowDown} alt='arrow' className='u-arrow' />
                                </Typography> */}
                                <Typography className='th-title'>Sub-category</Typography>
                              </Typography>
                            </TableCell>
                            <TableCell>Variable</TableCell>
                            <TableCell>Values</TableCell>
                            <TableCell>Units</TableCell>
                            {/* <TableCell>Value</TableCell>
                            <TableCell>Units</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableLoading ? (
                            <TableLoader colsSpan={7} />
                          ) : bodyData?.standards && bodyData?.standards.length ? (
                            bodyData?.standards.map((item: any, index: number) => {
                              if (
                                bodyData.facilityDetails?.[0]?.['cultivationProd'] ===
                                item.modelType
                              ) {
                                if (
                                  (!item?.defaultHectare || !item?.defaultAcre) &&
                                  !item?.isHide
                                ) {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>{item?.categoryView}</TableCell>
                                      <TableCell>{item?.subCategoryView}</TableCell>
                                      <TableCell>
                                        {item?.variablesView === 'Fertilizer P2O5' ? (
                                          <>
                                            Fertilizer P<sub>2</sub>O<sub>5</sub>
                                          </>
                                        ) : item?.variablesView === 'Fertilizer K2O' ? (
                                          <>
                                            Fertilizer K<sub>2</sub>O
                                          </>
                                        ) : (
                                          item?.variablesView
                                        )}
                                      </TableCell>
                                      {!item?.defaultAcre ? (
                                        <>
                                          <TableCell>
                                            {editOption ? (
                                              <TextField
                                                type='number'
                                                sx={{
                                                  '& .MuiOutlinedInput-root': {
                                                    height: '35px !important',
                                                  },
                                                }}
                                                value={roundOfData2(item?.acreValue)}
                                                onChange={(e: any) => {
                                                  item['acreValue'] = e?.target?.value
                                                    ? Number(e?.target?.value)
                                                    : '';
                                                  bodyData.standards[index] = item;
                                                  setBodyData({ ...bodyData });
                                                }}
                                              />
                                            ) : (
                                              roundOfData1(Number(item?.acreValue))
                                            )}
                                          </TableCell>
                                          <TableCell sx={{ textTransform: 'capitalize' }}>
                                            {item?.acreUnit}
                                          </TableCell>
                                        </>
                                      ) : !item?.defaultHectare ? (
                                        <>
                                          <TableCell>
                                            {editOption ? (
                                              <TextField
                                                type='number'
                                                sx={{
                                                  '& .MuiOutlinedInput-root': {
                                                    height: '35px !important',
                                                  },
                                                }}
                                                value={roundOfData2(item?.hectareValue)}
                                                onChange={(e: any) => {
                                                  item['hectareValue'] = e?.target?.value
                                                    ? Number(e?.target?.value)
                                                    : '';
                                                  bodyData.standards[index] = item;
                                                  setBodyData({ ...bodyData });
                                                }}
                                              />
                                            ) : (
                                              roundOfData1(Number(item?.hectareValue))
                                            )}
                                          </TableCell>
                                          <TableCell sx={{ textTransform: 'capitalize' }}>
                                            {item?.hectareUnit}
                                          </TableCell>
                                        </>
                                      ) : (
                                        <>
                                          <TableCell>
                                            {roundOfData1(Number(item?.acreValue))}
                                          </TableCell>
                                          <TableCell sx={{ textTransform: 'capitalize' }}>
                                            {item?.acreUnit}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                  );
                                } else {
                                  return <Typography key={index} />;
                                }
                              } else {
                                return <Typography key={index} />;
                              }
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5} align={'center'}>
                                No Records Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
          <Typography component={'div'} className='in-flex flex-sb'>
            <Button className='save-btn' onClick={backTab}>
              Back
            </Button>
            {redirectId && value === '3' && addOrView === 'view' ? (
              <Button
                className='save-btn'
                onClick={() => navigate(PrivateRoutes.DASHBOARD, { state: { id: redirectId } })}>
                Result dashboard
              </Button>
            ) : (
              <Button className='save-btn' onClick={submitTab}>
                {value === '3' ? 'Save and Generate Result' : 'Next'}
              </Button>
            )}
          </Typography>
        </Typography>
      </Grid>
      <Modal
        open={numberDetail.open}
        onClose={handleTruckClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box className='trans-box'>
          <Typography component={'div'} display={'flex'} justifyContent={'space-between'}>
            <Typography className='v-title'>
              Transportation Distance {numberDetail.heading} (in Miles)
            </Typography>
            <Typography className='v-title'>
              <Button
                className='cancel-btn btn-2r'
                onClick={() => {
                  numberArr.push({
                    addressData: [
                      {
                        distance: null,
                        fromAddress: null,
                        fromLat: null,
                        fromLng: null,
                        toAddress: null,
                        toLat: null,
                        toLng: null,
                      },
                    ],
                  });
                  setNumberArr([...numberArr]);
                }}
                sx={{
                  color: '#1976d2 !important',
                  borderColor: '#1976d2 !important',
                }}>
                Add Vendor
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='custom-form' sx={{ padding: '15px' }}>
            {numberArr.map((item: any, index: number) => {
              return (
                <Box className='p-rel' key={index}>
                  <Grid
                    container
                    columnSpacing={3.5}
                    rowSpacing={2.5}
                    sx={{ border: '1px solid #E8E8E8', padding: '10px', marginTop: '0px' }}>
                    {index !== 0 && (
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ position: 'absolute', right: '1%', top: '-20%' }}>
                        <Typography
                          component={'div'}
                          onClick={() => {
                            numberArr.splice(index, 1);
                            setNumberArr([...numberArr]);
                          }}
                          sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            width: '25px',
                            height: '25px',
                            border: '1px solid grey !important',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <img src={Close} alt='delete' />
                        </Typography>
                      </Grid>
                    )}
                    <Grid item md={4} sm={12} xs={12} sx={{ padding: '5px !important' }}>
                      <CustomInput
                        label='Vendor'
                        className='custom-input'
                        type={'text'}
                        disabled={addOrView === 'view'}
                        onChange={(e: any) => {
                          numberArr[index]['vendor'] = e.target.value;
                          setNumberArr([...numberArr]);
                        }}
                        value={item.vendor}
                      />
                    </Grid>
                    <Grid item md={8} />
                    <Grid item md={12} sm={12} xs={12} sx={{ padding: '5px !important' }}>
                      {item.addressData &&
                        item.addressData.map((row: any, rowIndex: number) => {
                          return (
                            <>
                              <Grid
                                item
                                textAlign={'end'}
                                md={12}
                                sm={12}
                                xs={12}
                                justifyContent={'end'}
                                alignSelf={'end'}>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    color: '#1976d2 !important',
                                  }}
                                  component={'div'}
                                  onClick={() => {
                                    if (rowIndex === 0) {
                                      const lastIndexObject =
                                        numberArr[index].addressData[
                                          Number(numberArr[index].addressData.length) - 1
                                        ];
                                      numberArr[index].addressData.push({
                                        distance: null,
                                        fromAddress: lastIndexObject?.toAddress
                                          ? lastIndexObject?.toAddress
                                          : null,
                                        fromLat: lastIndexObject?.toLat
                                          ? lastIndexObject?.toLat
                                          : null,
                                        fromLng: lastIndexObject?.toLng
                                          ? lastIndexObject?.toLng
                                          : null,
                                        toAddress: null,
                                        toLat: null,
                                        toLng: null,
                                      });
                                      setNumberArr([...numberArr]);
                                    } else {
                                      setNumberArr((pre: any) => {
                                        pre[index].addressData.splice(rowIndex, 1);
                                        return [...pre];
                                      });
                                    }
                                  }}>
                                  {addOrView !== 'view' ? (
                                    rowIndex === 0 ? (
                                      'Add  Destination'
                                    ) : rowIndex === Number(item.addressData.length - 1) ? (
                                      'Remove Destination'
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    <Typography />
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                columnSpacing={3.5}
                                rowSpacing={2.5}
                                sx={{ marginTop: '0px', marginLeft: '-5px' }}>
                                <Grid
                                  item
                                  md={4.5}
                                  sm={12}
                                  xs={12}
                                  key={rowIndex}
                                  sx={{ padding: '5px !important' }}>
                                  <Autocomplete
                                    options={{
                                      types: ['geocode', 'establishment'],
                                    }}
                                    className='auto-complete-input'
                                    apiKey={APIKEY}
                                    defaultValue={row.fromAddress}
                                    disabled={addOrView === 'view'}
                                    placeholder='From'
                                    onPlaceSelected={(place: any) => {
                                      numberArr[index]['addressData'][rowIndex]['fromAddress'] =
                                        place?.formatted_address;
                                      numberArr[index]['addressData'][rowIndex]['fromLat'] =
                                        place?.geometry?.location?.lat();
                                      numberArr[index]['addressData'][rowIndex]['fromLng'] =
                                        place?.geometry?.location?.lng();
                                      if (row.toLat && row.toLng) {
                                        const distance = getDistance(
                                          {
                                            latitude: place?.geometry?.location?.lat(),
                                            longitude: place?.geometry?.location?.lng(),
                                          },
                                          { latitude: row.toLat, longitude: row.toLng },
                                        );
                                        numberArr[index]['addressData'][rowIndex]['distance'] =
                                          Number(convertDistance(distance, 'mi').toFixed(2));
                                      }
                                      setNumberArr([...numberArr]);
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={4.3}
                                  sm={12}
                                  xs={12}
                                  sx={{ padding: '5px !important' }}>
                                  <Autocomplete
                                    className='auto-complete-input'
                                    apiKey={APIKEY}
                                    options={{
                                      types: ['geocode', 'establishment'],
                                    }}
                                    defaultValue={row.toAddress}
                                    placeholder='To'
                                    disabled={addOrView === 'view'}
                                    onPlaceSelected={(place: any) => {
                                      numberArr[index]['addressData'][rowIndex]['toAddress'] =
                                        place?.formatted_address;
                                      numberArr[index]['addressData'][rowIndex]['toLat'] =
                                        place?.geometry?.location?.lat();
                                      numberArr[index]['addressData'][rowIndex]['toLng'] =
                                        place?.geometry?.location?.lng();
                                      if (row.fromLat && row.fromLng) {
                                        const distance = getDistance(
                                          { latitude: row.fromLat, longitude: row.fromLng },
                                          {
                                            latitude: place?.geometry?.location?.lat(),
                                            longitude: place?.geometry?.location?.lng(),
                                          },
                                        );
                                        numberArr[index]['addressData'][rowIndex]['distance'] =
                                          Number(convertDistance(distance, 'mi').toFixed(2));
                                      }
                                      setNumberArr([...numberArr]);
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  sm={12}
                                  xs={12}
                                  sx={{ padding: '5px !important' }}>
                                  <CustomInput
                                    label={row.distance ? '' : 'Distance'}
                                    className='custom-input'
                                    type={'Number'}
                                    // disabled={true}
                                    disabled={addOrView === 'view'}
                                    onChange={(e: any) => {
                                      numberArr[index]['addressData'][rowIndex]['distance'] = e
                                        .target.value
                                        ? parseInt(e.target.value)
                                        : '';
                                      setNumberArr([...numberArr]);
                                    }}
                                    value={row.distance}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                    </Grid>
                  </Grid>
                  {/* <Typography
                    sx={{ cursor: 'pointer' }}
                    component={'div'}
                    onClick={() => {
                      if (index === 0) {
                        numberArr.push({
                          addressData: [
                            {
                              distance: null,
                              fromAddress: null,
                              fromLat: null,
                              fromLng: null,
                              toAddress: null,
                              toLat: null,
                              toLng: null,
                            },
                          ],
                        });
                      } else {
                        numberArr.splice(index, 1);
                      }
                      // numberArr.splice(index, 1);
                      setNumberArr([...numberArr]);
                    }}>
                    {addOrView !== 'view' ? (
                      index === 0 ? (
                        <img src={Radd} className='add' alt='add' />
                      ) : (
                        <img src={Rremove} className='add' alt='delete' />
                      )
                    ) : (
                      <Typography />
                    )}
                  </Typography> */}
                </Box>
              );
            })}
            <Typography component={'div'} className='in-flex flex-end' sx={{ marginTop: '8px' }}>
              <Button className='cancel-btn btn-2r' onClick={handleTruckClose}>
                Cancel
              </Button>
              <Button className='save-btn' onClick={submitNumber}>
                Save
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Modal>
      {withoutProcessingOpen && (
        <ConfirmationModel
          onClose={(isValid: any) => {
            console.log(isValid);
            if (isValid) {
              handleWithoutProcessing(false);
              setValue('2');
              setSubValue('22');
            } else {
              handleWithoutProcessing(false);
            }
          }}
          onSubmit={() => {
            handleWithoutProcessing(false);
            setWithoutProcessing(true);
            setValue('3');
          }}
          heading={'Add Processing Facility'}
          btnName2={'Yes'}
          btnName1='No'
          subHeading={'Are you sure you want to proceed without processing facility?'}
        />
      )}
    </Grid>
  );
};

export default AddScenario;
