import { Typography, styled } from '@mui/material';
import usePagination from '@mui/material/usePagination';
import React from 'react';
import { DefaultPerPage } from 'utils/ArrayContans';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

interface PaginationProps {
  total: number;
  page: number;
  changePagination: (src: any, page: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const { total, changePagination, page } = props;
  const { items } = usePagination({
    count: total / DefaultPerPage,
    page: page,
    onChange: changePagination,
  });

  return (
    <Typography component={'div'} className='d-flex-a flex-sb mt-24'>
      <Typography className='page-left-text'>
        Showing 1 to {DefaultPerPage} of {total} entries
      </Typography>
      <nav>
        <List className='pagination'>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = '…';
            } else if (type === 'page') {
              children = (
                <button
                  type='button'
                  style={{
                    backgroundColor: selected ? '#2A89E1' : '#ffffff',
                    border: 'none',
                    borderRadius: '7.4px',
                    color: selected ? '#fff' : '#111',
                    fontSize: '14px',
                    fontWeight: selected ? 'bold' : undefined,
                    height: '33.385px',
                    margin: '0 5px 0 10px',
                    width: '33.385px',
                  }}
                  {...item}>
                  {page}
                </button>
              );
            } else {
              children = (
                <button type='button' {...item}>
                  {type}
                </button>
              );
            }
            return <li key={index}>{children}</li>;
          })}
        </List>
      </nav>
    </Typography>
  );
};

export default Pagination;
