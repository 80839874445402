export const StandardType = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'dualPurpose',
    label: 'Dual',
  },
  {
    value: 'seedPurpose',
    label: 'Hemp Oil',
  },
  {
    value: 'strawPurpose',
    label: 'Hemp Fiber',
  },
];

export const FacilityType = [
  {
    label: 'Farm',
    value: 'farm',
  },
  {
    label: 'Processing Plant',
    value: 'processingPlant',
  },
];

export const FacilityTypeValue: any = {
  processingPlant: 'Processing Plant',
  farm: 'Farm',
};

export const DefaultPerPage = 50;

export const SoilHumidity = [
  {
    value: 'wet',
    label: 'WET(more than 75 % of humidity)',
  },
  {
    value: 'medium',
    label: 'MEDIUM(between 25 - 75 % of humidity)',
  },
  {
    value: 'dry',
    label: 'DRY(less than 25 % of humidity)',
  },
];

export const FacilityModelType = [
  {
    value: 'dualPurpose',
    label: 'Dual',
  },
  {
    value: 'seedPurpose',
    label: 'Hemp Oil',
  },
  {
    value: 'strawPurpose',
    label: 'Hemp Fiber',
  },
];

export const TransportationVia = [
  {
    value: 'lorry',
    label: 'Dump Truck',
  },
  {
    value: 'truck',
    label: 'Semi Truck',
  },
  {
    value: 'car',
    label: 'Reefer Truck',
  },
];

export const WasteTreatment = [
  {
    value: 'product',
    label: 'PRODUCT',
  },
  {
    value: 'waste',
    label: 'WASTE',
  },
];

export const YesOrNoOption = [
  {
    value: 'yes',
    label: 'YES',
  },
  {
    value: 'no',
    label: 'NO',
  },
];
