import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { postRequest } from 'utils/Handlers';
import Storage from 'utils/Storage';

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_ORGANIZATION_LOGO,
  USER_REFRESH_TOKEN,
} from '../types/UserTypes';
import { deleteData } from 'services/CommonServices';

export const login = (email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const params = {
      data: {
        email,
        password,
      },
      token: '',
      url: MASTER_ENDPOINT.Login,
    };
    return await postRequest(params)
      .then((response: any) => {
        const { accessToken, refreshToken: refreshToken1 } = response;
        const userData: any = jwtDecode(String(accessToken));
        userData['token'] = accessToken;
        userData['refreshToken'] = refreshToken1;
        dispatch({
          payload: userData,
          type: USER_LOGIN_SUCCESS,
        });
        dispatch({
          payload: userData?.orgImageId,
          type: USER_ORGANIZATION_LOGO,
        });
        // Storage.normalSetItem(Storage.KEYS.ORGANIZATION_LOGO, userData?.orgImageId);
        Storage.setItem(Storage.KEYS.ORGANIZATION_LOGO, userData?.orgImageId);
        Storage.setItem(Storage.KEYS.REFRESH_TOCKEN_ID, userData?.refreshTokenId);
        if (accessToken && refreshToken(refreshToken1) && userData?.id) {
          const tokenData: any = {
            refreshToken: refreshToken1,
            token: accessToken,
          };
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
        }
      })
      .catch((error: any) => {
        let errorMessage = '';
        if (error.error.data) {
          errorMessage = error.error.data.message;
        }
        dispatch({
          payload: errorMessage,
          type: USER_LOGIN_FAIL,
        });

        toast.error(errorMessage || 'Invalid username or password' || String(error), alertOptions);
      });
  };
};

export const refreshToken: any = (token: string) => (dispatch: any) => {
  dispatch({
    payload: token,
    type: USER_REFRESH_TOKEN,
  });
};

export const logout = async (dispatch: any) => {
  const refreshTokenId: any = Storage.getItem(Storage.KEYS.REFRESH_TOCKEN_ID);
  if (refreshTokenId) {
    await deleteData(refreshTokenId, 'auth/logout');
  }
  dispatch({
    type: USER_LOGOUT,
  });
  localStorage.clear();
  Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
  Storage.removeItem(Storage.KEYS.MENUS);
  Storage.removeItem(Storage.KEYS.REFRESH_TOCKEN_ID);
};
