import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoutes as PrivateRoutesDirectives } from './routeConstans';
import Dashboard from 'pages/private/dashboard';
import PrivateLayout from 'pages/private';
import Oraganization from 'pages/private/organization';
import Facilities from 'pages/private/facilities';
import Scenarion from 'pages/private/scenario';
import Standard from 'pages/private/standard';
import Myprofile from 'pages/private/myprofile';
import Changepassword from 'pages/private/changepassword';
import Users from 'pages/private/users';
import AddUser from 'pages/private/users/add';
import FacilityAdd from 'pages/private/facilities/add';
import AddScenario from 'pages/private/scenario/add';

const PrivateRoutes = () => {
  const {
    DASHBOARD,
    ORGANIZATION,
    FACILITIES,
    SCENARIO,
    STANDARD,
    MYPROFILE,
    CHANGEPASSWORD,
    USERS,
  } = PrivateRoutesDirectives;
  return (
    <Routes>
      <Route path='/' element={<Navigate to={DASHBOARD} replace />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout path={DASHBOARD}>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <PrivateLayout path={MYPROFILE}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={CHANGEPASSWORD}
        element={
          <PrivateLayout path={CHANGEPASSWORD}>
            <Changepassword />
          </PrivateLayout>
        }
      />
      <Route
        path={USERS.LIST}
        element={
          <PrivateLayout path={USERS.LIST}>
            <Users />
          </PrivateLayout>
        }
      />
      <Route
        path={USERS.ADD}
        element={
          <PrivateLayout path={USERS.ADD}>
            <AddUser />
          </PrivateLayout>
        }
      />
      <Route
        path={ORGANIZATION}
        element={
          <PrivateLayout path={ORGANIZATION}>
            <Oraganization />
          </PrivateLayout>
        }
      />
      <Route
        path={FACILITIES.LIST}
        element={
          <PrivateLayout path={FACILITIES.LIST}>
            <Facilities />
          </PrivateLayout>
        }
      />
      <Route
        path={FACILITIES.ADD}
        element={
          <PrivateLayout path={FACILITIES.ADD}>
            <FacilityAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={SCENARIO.LIST}
        element={
          <PrivateLayout path={SCENARIO.LIST}>
            <Scenarion />
          </PrivateLayout>
        }
      />
      <Route
        path={SCENARIO.TYPE}
        element={
          <PrivateLayout path={SCENARIO.TYPE}>
            <AddScenario />
          </PrivateLayout>
        }
      />
      <Route
        path={STANDARD}
        element={
          <PrivateLayout path={STANDARD}>
            <Standard />
          </PrivateLayout>
        }
      />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default PrivateRoutes;
