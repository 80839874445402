import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ArrowDown from 'assets/png/arrow_down.svg';
import Edit from 'assets/png/edit.png';
// import Delete from 'assets/png/delete.png';
import { PrivateRoutes } from 'routes/routeConstans';
import { Link, useNavigate } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import ConfirmationModel from 'components/ConfirmationModal';
import Pagination from 'components/Pagination';
import { DefaultPerPage } from 'utils/ArrayContans';
import TableLoader from 'components/TableLoader';

const drawerStyle = {
  nameLogo: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: '#28A9E1',
    color: '#fff !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins-SemiBold !important',
    fontSize: '14px !important',
    lineHeight: '24px !important',
    fontWeight: '500',
  },
};

const Users = () => {
  const navigate = useNavigate();
  const [param, setParam] = useState<any>({
    page: 1,
    order: 'asc',
    sortby: 'firstName',
  });
  const [tableData, setTableData] = useState<{
    loader: boolean;
    total: number;
    list: any;
  }>({
    loader: false,
    total: 0,
    list: [],
  });
  const { ADD } = PrivateRoutes.USERS;
  const [deleteItem, setDeleteItem] = useState<any>({
    id: '',
    open: false,
  });

  const editUser = (row: any) => {
    navigate('/manage-users/add', { state: { row } });
  };

  const handleClose = () => {
    setDeleteItem({
      id: '',
      open: false,
    });
  };

  const handleDelete = () => {
    deleteData(deleteItem.id, MASTER_ENDPOINT.user, true).then(() => {
      handleClose();
      getUsers();
    });
  };

  const getUsers = () => {
    setTableData({
      ...tableData,
      loader: true,
      list: [],
    });
    getAllListData(
      `${MASTER_ENDPOINT.user}?skip=${
        param.page * DefaultPerPage - DefaultPerPage
      }&take=${DefaultPerPage}&order=${param.order}&sortby=${param.sortby}`,
    ).then((res) => {
      setTableData({
        ...tableData,
        loader: false,
        list: res?.data,
        total: res?.total,
      });
    });
  };

  const sortByFunction = (name: string) => {
    if (param.sortby === name) {
      setParam({
        ...param,
        order: param.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setParam({
        ...param,
        sortby: name,
        order: 'asc',
      })
    }
  };

  useEffect(() => {
    getUsers();
  }, [param]);

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='d-flex-a flex-sb pb-24'>
          <Typography className='commontitle'>Manage Users</Typography>
          <Link to={ADD}>
            <Button className='save-btn' startIcon={<AddIcon sx={{ fill: '#ffffff' }} />}>
              Add user
            </Button>
          </Link>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='custom-table'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell className='w-20'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography component={'div'} className='arrow-box' onClick={() => sortByFunction('firstName')}>
                        <img src={ArrowDown} alt='arrow' className='d-arrow' />
                        <img src={ArrowDown} alt='arrow' className='u-arrow' />
                      </Typography>
                      <Typography className='th-title'>Name</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-20'>
                    <Typography component={'div'} className='d-flex-a'>
                      {/* <Typography component={'div'} className='arrow-box'>
                        <img src={ArrowDown} alt='arrow' className='d-arrow' />
                        <img src={ArrowDown} alt='arrow' className='u-arrow' />
                      </Typography> */}
                      <Typography className='th-title'>User Type</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-25'>
                    <Typography component={'div'} className='d-flex-a'>
                      {/* <Typography component={'div'} className='arrow-box'>
                        <img src={ArrowDown} alt='arrow' className='d-arrow' />
                        <img src={ArrowDown} alt='arrow' className='u-arrow' />
                      </Typography> */}
                      <Typography className='th-title'>Email</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-15'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography component={'div'} className='arrow-box' onClick={() => sortByFunction('status')}>
                        <img src={ArrowDown} alt='arrow' className='d-arrow' />
                        <img src={ArrowDown} alt='arrow' className='u-arrow' />
                      </Typography>
                      <Typography className='th-title'>Status</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-10'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={'userRow'}>
                {tableData.loader ? (
                  <TableLoader colsSpan={6} />
                ) : tableData?.list.length ? (
                  tableData?.list?.map((row: any) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <IconButton
                            type={'button'}
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography component={'div'} sx={drawerStyle.nameLogo}>
                              {String(row?.firstName?.[0] + row?.lastName?.[0]).toUpperCase()}
                            </Typography>
                          </IconButton>
                          <Typography
                            sx={{
                              textTransform: 'capitalize',
                              fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
                              fontSize: '14px !important',
                            }}>
                            {row.firstName + ' ' + row.lastName}
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell>{row.roleId ? row?.role?.name : ''}</TableCell>
                      <TableCell>{row.login.email}</TableCell>
                      <TableCell>{row.status ? 'Active' : 'Inactive'}</TableCell>
                      <TableCell>
                        <Typography style={{ paddingLeft: '20px' }}>
                          <img
                            src={Edit}
                            alt='edit'
                            className='mr-24'
                            onClick={() => editUser(row)}
                          />
                          {/* <img
                            src={Delete}
                            alt='edit'
                            onClick={() =>
                              setDeleteItem({
                                id: row._id,
                                open: true,
                              })
                            }
                          /> */}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align='center' colSpan={5}>
                      <Typography component={'div'} className='noRecordsText'>
                        No Records Found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            total={tableData.total}
            page={param.page}
            changePagination={(e: any, page: number) => setParam({ ...param, page: page })}
          />
        </Typography>
      </Grid>
      {deleteItem.open && (
        <ConfirmationModel
          onClose={handleClose}
          onSubmit={handleDelete}
          heading={'Delete?'}
          btnName2='Delete'
          subHeading={'Are you sure you want to Delete this facilities?'}
        />
      )}
    </Grid>
  );
};

export default Users;
