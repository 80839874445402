/* eslint-disable react/display-name */
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, TextField } from '@mui/material';
// import AddIcon from 'assets/png/Addplus.png';
import React, { ReactElement } from 'react';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  onBlur?: (src: any) => void;
  extraStyle?: any;
  extraStyle1?: any;
  extraInputStyle?: any;
  extraInputBaseStyle?: any;
  value?: any;
  variant?: any;
  error?: any;
  name?: string;
  type?: string;
  inputProps?: any;
  id?: string;
  maxLength?: number;
  isTextArea?: boolean;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  label?: string;
};

const CustomInput = React.forwardRef((props: InputProps, ref: any): ReactElement => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    placeHolder,
    className,
    onChange,
    onBlur,
    extraStyle,
    // extraInputStyle,
    // extraInputBaseStyle,
    value,
    variant,
    error,
    name = '',
    type = 'text',
    inputProps = {},
    id = '',
    maxLength,
    isTextArea = false,
    disabled,
    minRows = 3,
    maxRows = 4,
    label,
  } = props;

  return (
    <>
      <TextField
        placeholder={variant ? '' : placeHolder}
        label={label}
        className={className}
        onChange={onChange && onChange}
        onBlur={onBlur && onBlur}
        name={name}
        disabled={disabled}
        ref={ref}
        id={id}
        style={extraStyle}
        value={value}
        defaultValue={value}
        variant={variant}
        error={error}
        helperText={error}
        type={showPassword ? 'text' : type}
        InputProps={
          type === 'password'
            ? {
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }
            : { ...inputProps }
        }
        inputProps={{
          maxLength: maxLength,
        }}
        multiline={isTextArea ? true : false}
        minRows={isTextArea ? (minRows ? minRows : 3) : 1}
        maxRows={maxRows ? maxRows : 4}
      />
    </>
  );
});

export default CustomInput;
