import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// import Loading from '../assets/images/Loading.gif';
import { Vortex } from 'react-loader-spinner';

const Loader = (props: any) => {
  const { openLoader } = props;

  return (
    <Typography>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          opacity: '0.5 !important',
        }}
        open={openLoader}>
        {/* <img src={Loading} style={{ maxHeight: '200px' }} /> */}
        {/* <ColorRing
          visible={true}
          height='80'
          width='80'
          ariaLabel='blocks-loading'
          wrapperStyle={{}}
          wrapperClass='blocks-wrapper'
          colors={['#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1']}
        /> */}
        <Vortex
          visible={true}
          height="80"
          width="80"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={['#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1', '#28a9e1']}
        />
      </Backdrop>
    </Typography>
  );
};

export default Loader;
