export const PublicRoutes = {
  LOGIN: '/login',
};

export const PrivateRoutes = {
  DASHBOARD: '/dashboard',
  ORGANIZATION: '/organization',
  FACILITIES: {
    LIST: '/facilities',
    ADD: '/facilities/:add',
  },
  SCENARIO: {
    LIST: '/scenario',
    TYPE: '/scenario/:type',
  },
  STANDARD: '/standard',
  MYPROFILE: '/my-profile',
  CHANGEPASSWORD: '/change-password',
  USERS: {
    LIST: '/manage-users',
    ADD: '/manage-users/add',
  },
};
