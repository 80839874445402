import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Farmhouse from 'assets/png/farmhouse.png';
import Zasti from 'assets/png/zastilogo.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { loginSchema } from 'utils/ValidatorSchema';
import { LoginForm } from 'types';
import { useAppDispatch } from 'store/hooks';
import { login } from 'store/actions/UserAction';
import CustomInput from 'components/CustomInput/customInput';
import moment from 'moment';

const Login = () => {
  const dispatch: any = useAppDispatch();
  const { handleSubmit, control, formState } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(loginSchema),
  });

  const submitForm = (data: any) => {
    dispatch(login(data['email'], data['password']));
  };

  return (
    <Grid container sx={{ minHeight: '100vh' }}>
      <Grid
        item
        md={7.5}
        sm={12}
        sx={{
          backgroundImage: `url(${Farmhouse})`,
        }}
        className='login-bg'>
        <Typography component={'div'} className='log-footer laptop-footer'>
          &copy; copyright reserved {moment().format('YYYY')} ZASTI Inc | All rights reserved
        </Typography>
      </Grid>
      <Grid item md={4.5} sm={12} sx={{ margin: 'auto' }}>
        <Typography component={'div'} className='login-form-box'>
          <img
            style={{ height: '122px', marginBottom: '45px', objectFit: 'contain' }}
            src={Zasti}
            alt='IndHemp'
          />
          <Typography className='log-h1'>Keep Connected</Typography>
          <Typography className='log-p'>
            Sign in with your credential to access your account
          </Typography>
          <form className='custom-form' onSubmit={handleSubmit(submitForm)}>
            <Grid container rowSpacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='email'
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        label='Email address'
                        className='custom-input'
                        onChange={onChange}
                        ref={ref}
                        value={value}
                        name={name}
                        error={formState.errors.email?.message}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='password'
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        label='Password'
                        type='password'
                        className='custom-input'
                        onChange={onChange}
                        value={value}
                        name={name}
                        ref={ref}
                        error={formState.errors.password?.message}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Typography component={'div'} className='d-flex-a' sx={{ margin: '24px 0 24px 0' }}>
              {/* <Checkbox sx={{ padding: '0px' }} />
              <Typography className='log-span'>Keep me signed in</Typography> */}
            </Typography>
            <Button
              className='save-btn'
              sx={{ marginBottom: '24px !important', minWidth: '100% !important' }}
              type='submit'>
              Sign In
            </Button>
            <Typography component={'div'} className='log-footer mobile-footer-none'>
              &copy; copyright reserved 2017-2023 ZASTI Inc | All rights reserved
            </Typography>
            <img
              src={Zasti}
              alt='zasti'
              className='log-foot-icon'
            />
          </form>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
