import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Incorrect Password',
    ),
});

export const changePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Retype new password is required')
    .when('newPassword', {
      is: (newpassword: any) => (newpassword && newpassword.length > 0 ? true : false),
      then: () => Yup.string().oneOf([Yup.ref('newPassword')], "Password doesn't match"),
    }),
  currentPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password must contain one uppercase, one lowercase, one number and one special case character',
    ),
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password must contain one uppercase, one lowercase, one number and one special case character',
    ),
});

export const orgSchema = Yup.object().shape({
  name: Yup.string().trim().required('Organization Name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  address: Yup.string().notRequired(),
  website: Yup.string().notRequired(),
});

export const userSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string().notRequired(),
  // .required('Password is required')
  // .min(8, 'Password must be at least 8 characters')
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
  //   'Password must contain one uppercase, one lowercase, one number and one special case character',
  // ),
  userType: Yup.number().typeError('User Type is required').required('User Type is required'),
  status: Yup.boolean().required('Status is required'),
});

export const facilitiesSchema = Yup.object().shape({
  name: Yup.string().trim().required('Facility Name is required'),
  type: Yup.string().required('Facility Type is required'),
  address: Yup.string().notRequired(),
});

export const myprofileSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  address: Yup.string().notRequired(),
  phoneNumber: Yup.string().notRequired(),
  country: Yup.string().notRequired(),
  state: Yup.string().notRequired(),
  city: Yup.string().notRequired(),
  zip: Yup.string().notRequired(),
  email: Yup.string().required('Email is required').email('Email is invalid'),
});
