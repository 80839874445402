import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableLoader from 'components/TableLoader';
import { TableHead, Typography } from '@mui/material';
import { roundOfData } from 'utils/Config';

interface ListProps {
  data: any;
  loader: boolean;
}

const ResultTable = (props: ListProps) => {
  const { data, loader } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell className='blue-header'>Activities</TableCell>
            <TableCell className='blue-header'>Units</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loader ? (
            <TableLoader colsSpan={2} />
          ) : data.length ? (
            data
              .sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order))
              .map((result: any, index: number) => (
                <TableRow key={index}>
                  <TableCell className='w-30'>{result?.activities}</TableCell>
                  <TableCell className='w-40'>
                    {roundOfData(result?.value)} {result?.unit}
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell align='center' colSpan={2}>
                <Typography component={'div'} className='noRecordsText'>
                  No Records Found!
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultTable;
