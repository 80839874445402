import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import { Chart } from 'react-google-charts';
import { getAllListData } from 'services/CommonServices';
import { CHART_COLOR, MASTER_ENDPOINT } from 'utils/Constant';
import Loader from 'components/Loader';
import moment from 'moment';
import ResultTable from './resultTable';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { roundOfData, roundOfData1 } from 'utils/Config';
import Close from 'assets/png/close.png';
import CorbonFootPrintWithLca from './corbonFootPrintWithLca';
import CorbonFootPrintWithFormerScope from './corbonFootPrintWithFormerScope';
import CorbonFootPrintWithOnProcessScope from './corbonFootPrintWithOnProcessScope';
import fileDownload from 'js-file-download';
import axios from 'axios';
import Storage from 'utils/Storage';
import TableLoader from 'components/TableLoader';
import DownloadPng from 'assets/png/download.png';
import ArrowDown from 'assets/png/arrow_down.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: ' 6px',
  background: ' #FFF',
  boxShadow: '0px 2px 4px 0px rgba(147, 147, 147, 0.25)',
  padding: '24px',
  maxHeight: '80vh',
  overflow: 'auto !important',
};

export const options = {
  // title: 'Proportion of emissions per activity',
  chartArea: { width: '65%', fontName: 'Poppins-Regular' },
  legend: { position: 'none' },
  hAxis: {
    title: 'Percentage (%)',
    minValue: 0,
    textStyle: { color: '#111', fontName: 'Poppins-Regular', fontSize: '12', fontWeight: '600' },
  },
  vAxis: {
    title: '',
    textStyle: { color: '#111', fontName: 'Poppins-Regular', fontSize: '12', bold: true },
  },
};

export const scopeoptions = {
  // title: 'Proportion of emissions per activity',
  chartArea: { width: '65%', fontName: 'Poppins-Regular' },
  legend: { position: 'none' },
  hAxis: {
    title: '',
    minValue: 0,
    textStyle: { color: '#111', fontName: 'Poppins-Regular', fontSize: '12', fontWeight: '600' },
  },
  vAxis: {
    title: '',
    textStyle: { color: '#111', fontName: 'Poppins-Regular', fontSize: '12', bold: true },
  },
};

export const pieoptions = {
  title: '',
  chartArea: { width: '100%', fontName: 'Poppins-Regular' },
  is3D: true,
  colors: [CHART_COLOR.scope1, CHART_COLOR.scope2, CHART_COLOR.scope3],
  legend: { position: 'bottom' },
  pieSliceTextStyle: {
    color: 'black',
  },
};

const tableCorbon = {
  corbon: {
    backgroundColor: `${CHART_COLOR.corbonBalance} !important`,
    border: '1px solid #240f0f',
    fontWeight: 'bold',
    // color: '#fff',
  },
  scope1: {
    backgroundColor: `${CHART_COLOR.scope1} !important`,
    border: '1px solid #240f0f',
    fontWeight: 'bold',
  },
  scope2: {
    backgroundColor: `${CHART_COLOR.scope2} !important`,
    border: '1px solid #240f0f',
    fontWeight: 'bold',
    // color: '#fff !important',
  },
  scope3: {
    backgroundColor: `${CHART_COLOR.scope3} !important`,
    border: '1px solid #240f0f',
    fontWeight: 'bold',
    // color: '#fff !important',
  },
  co2: {
    backgroundColor: CHART_COLOR.co2Hemp,
    border: '1px solid #240f0f',
    fontWeight: 'bold',
    // color: '#fff !important',
  },
};

const Dashboard: any = () => {
  const resultHorizantalBarChart = React.useRef<any>();
  const resultHorizantalPieChart = React.useRef<any>();
  const resultHorizantalSignleChart = React.useRef<any>();
  const formerScopeBarChart = React.useRef<any>();
  const processcopeBarChart = React.useRef<any>();
  const formerScopePieChart = React.useRef<any>();
  const processScopePieChart = React.useRef<any>();
  const location: any = useLocation();
  const [param, setParam] = useState<any>({
    order: 'asc',
    sortby: 'name',
  });
  const [list, setList] = useState<any>({});
  const [screnarioList, setScenarioList] = useState<any>([]);
  const [scenarioId, setScenarioId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = React.useState('1');
  const [tableLoader, setTableLoader] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  useEffect(() => {
    getScenario();
  }, [param]);

  useEffect(() => {
    if (scenarioId) {
      scenarioResult(scenarioId);
    }
  }, [scenarioId]);

  const scenarioResult = (sId: string) => {
    setTableLoader(true);
    setList({});
    getAllListData(`${MASTER_ENDPOINT.Scenario}/result/${sId}`)
      .then((resp: any) => {
        setList(resp?.data);
        setTableLoader(false);
      })
      .catch(() => {
        setTableLoader(false);
      });
  };

  const getScenario = () => {
    setLoading(true);
    getAllListData(`${MASTER_ENDPOINT.Scenario}?order=${param.order}&sortby=${param.sortby}`)
      .then((resp: any) => {
        setLoading(false);
        const respData = resp?.data;
        setScenarioList(respData);
        if (!scenarioId) {
          if (!_.isEmpty(location.state)) {
            const id = location.state.id;
            setScenarioId(id);
          } else {
            if (respData && respData?.length) {
              setScenarioId(respData[0]?._id);
            }
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleDownload = () => {
    if (scenarioId && screnarioList.length) {
      const TokenStatus: any = Storage.getTokens();
      const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
      setLoading(true);
      const postData = {
        resultHorizantalBarChart: resultHorizantalBarChart.current.getChart().getImageURI(),
        formerScopePieChart: formerScopePieChart.current.getChart().getImageURI(),
        formerScopeBarChart: formerScopeBarChart.current.getChart().getImageURI(),
        processScopePieChart: processScopePieChart.current.getChart().getImageURI(),
        processcopeBarChart: processcopeBarChart.current.getChart().getImageURI(),
        resultHorizantalPieChart: resultHorizantalPieChart.current.getChart().getImageURI(),
        resultHorizantalSignleChart: resultHorizantalSignleChart.current.getChart().getImageURI(),
      };
      axios
        .post(
          process.env.REACT_APP_APIURL + MASTER_ENDPOINT.Dashboard + '/word-report/' + scenarioId,
          postData,
          {
            responseType: 'blob',
            headers: {
              Authorization: 'Bearer '.concat(token),
            },
          },
        )
        .then((res: any) => {
          fileDownload(
            res.data,
            `${
              screnarioList[screnarioList.findIndex((item: any) => item._id === scenarioId)].name
            }.docx`,
          );
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const sortByFunction = (name: string) => {
    if (param.sortby === name) {
      setParam({
        ...param,
        order: param.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setParam({
        ...param,
        sortby: name,
        order: 'asc',
      });
    }
  };

  return (
    <Grid container>
      <Typography component={'div'} sx={{ display: 'none' }}>
        {!tableLoader && (
          <>
            <Chart
              chartType='BarChart'
              width='100%'
              height='339px'
              data={[
                ['', '', { role: 'style' }],
                [
                  'Pre-Farm',
                  Object.keys(list).length
                    ? Number((list?.emissionProportion?.preFarm * 100).toFixed(2))
                    : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                ],
                [
                  'On-Farm',
                  Object.keys(list).length
                    ? Number((list?.emissionProportion?.onFarm * 100).toFixed(2))
                    : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                ],
                [
                  'Process',
                  Object.keys(list).length
                    ? Number((list?.emissionProportion?.onProcess * 100).toFixed(2))
                    : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                ],
                [
                  'Post Process',
                  Object.keys(list).length
                    ? Number((list?.emissionProportion?.postProcess * 100).toFixed(2))
                    : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                ],
              ]}
              options={options}
              getChartWrapper={(w) => (resultHorizantalBarChart.current = w)}
            />
            <Chart
              chartType='ColumnChart'
              width='100%'
              height={'309px'}
              style={{ marginLeft: '20px' }}
              data={[
                ['Element', 'Density', { role: 'annotation' }, { role: 'style' }],
                [
                  Object.keys(list).length
                    ? list?.resultAndCarbonFootPrint?.carbonFootPrint?.corbonBalance?.[2]
                        ?.activities
                    : '',
                  Object.keys(list).length
                    ? Number(
                        list?.resultAndCarbonFootPrint?.carbonFootPrint?.corbonBalance?.[2]?.value.toFixed(
                          2,
                        ),
                      )
                    : 0,
                  `${
                    Object.keys(list).length
                      ? roundOfData1(
                          list?.resultAndCarbonFootPrint?.carbonFootPrint?.corbonBalance?.[2]
                            ?.value,
                        )
                      : ''
                  }`,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.greenColor}; fill-opacity: 0.95;`,
                ],
                // ['', '', ''],
              ]}
              options={{
                chartArea: { width: '65%', fontName: 'Poppins-Regular' },
                legend: { position: 'none' },
              }}
              getChartWrapper={(w) => (resultHorizantalSignleChart.current = w)}
            />
            <Chart
              chartType='PieChart'
              data={[
                ['Task', 'Hours per Day'],
                [
                  'Oil',
                  Object.keys(list).length
                    ? list?.lcaOilResult?.corbonBalance[2].value >= 0
                      ? 0
                      : Number((list?.lcaOilResult?.corbonBalance[2].value * -1).toFixed(2))
                    : 0,
                ],
                [
                  'Cake',
                  Object.keys(list).length
                    ? list?.lcaCakeResult?.corbonBalance[2].value >= 0
                      ? 0
                      : Number((list?.lcaCakeResult?.corbonBalance[2].value * -1).toFixed(2))
                    : 0,
                ],
                [
                  'Hull',
                  Object.keys(list).length
                    ? list?.lcaHullResult?.corbonBalance[2].value >= 0
                      ? 0
                      : Number((list?.lcaHullResult?.corbonBalance[2].value * -1).toFixed(2))
                    : 0,
                ],
                // [
                //   'Bast Fiber',
                //   Object.keys(list).length
                //     ? list?.lcaBastFiberResult?.corbonBalance[2].value >= 0
                //       ? 0
                //       : Number((list?.lcaBastFiberResult?.corbonBalance[2].value * -1).toFixed(2))
                //     : 0,
                // ],
                [
                  'Long Bast Fiber',
                  Object.keys(list).length
                    ? list?.lcaLongBastFiberesult?.corbonBalance[2].value >= 0
                      ? 0
                      : Number(
                          (list?.lcaLongBastFiberesult?.corbonBalance[2].value * -1).toFixed(2),
                        )
                    : 0,
                ],
                [
                  'Short Bast Fiber',
                  Object.keys(list).length
                    ? list?.lcaShortBastFiberesult?.corbonBalance[2].value >= 0
                      ? 0
                      : Number(
                          (list?.lcaShortBastFiberesult?.corbonBalance[2].value * -1).toFixed(2),
                        )
                    : 0,
                ],
                [
                  'Hurd',
                  Object.keys(list).length
                    ? list?.lcaHurdResult?.corbonBalance[2].value >= 0
                      ? 0
                      : Number((list?.lcaHurdResult?.corbonBalance[2].value * -1).toFixed(2))
                    : 0,
                ],
                [
                  'Dust',
                  Object.keys(list).length
                    ? list?.lcaDustResult?.corbonBalance[2].value >= 0
                      ? 0
                      : Number((list?.lcaDustResult?.corbonBalance[2].value * -1).toFixed(2))
                    : 0,
                ],
              ]}
              options={{
                legend: { position: 'right' },
              }}
              width={'100%'}
              height={'339px'}
              getChartWrapper={(w) => (resultHorizantalPieChart.current = w)}
            />
            <Chart
              chartType='PieChart'
              data={[
                ['Task', 'Hours per Day'],
                [
                  'SCOPE 1',
                  Object.keys(list).length ? Number(list.farmerScope.scope1.toFixed(2)) : 0,
                ],
                [
                  'SCOPE 2',
                  Object.keys(list).length ? Number(list.farmerScope.scope2.toFixed(2)) : 0,
                ],
                [
                  'SCOPE 3',
                  Object.keys(list).length ? Number(list.farmerScope.scope3.toFixed(2)) : 0,
                ],
              ]}
              options={pieoptions}
              width={'100%'}
              height={'327px'}
              getChartWrapper={(w) => (formerScopePieChart.current = w)}
            />
            <Chart
              chartType='BarChart'
              width='100%'
              height='339px'
              data={[
                ['', '', { role: 'style' }],
                [
                  'CO2 HEMP SEQUESTRATION',
                  Object.keys(list).length
                    ? Number(list.farmerScope.co2HempSEQUESTRATION.toFixed(2))
                    : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.farmerScope?.co2HempSEQUESTRATION > 0
                      ? CHART_COLOR.redColor
                      : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
                [
                  'SCOPE 1',
                  Object.keys(list).length ? Number(list.farmerScope.scope1.toFixed(2)) : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.farmerScope?.scope1 > 0 ? CHART_COLOR.redColor : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
                [
                  'SCOPE 2',
                  Object.keys(list).length ? Number(list.farmerScope.scope2.toFixed(2)) : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.farmerScope?.scope2 > 0 ? CHART_COLOR.redColor : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
                [
                  'SCOPE 3',
                  Object.keys(list).length ? Number(list.farmerScope.scope3.toFixed(2)) : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.farmerScope?.scope3 > 0 ? CHART_COLOR.redColor : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
              ]}
              options={scopeoptions}
              getChartWrapper={(w) => (formerScopeBarChart.current = w)}
            />
            <Chart
              chartType='PieChart'
              data={[
                ['Task', 'Hours per Day'],
                [
                  'SCOPE 1',
                  Object.keys(list).length ? Number(list.onProcessScope.scope1.toFixed(2)) : 0,
                ],
                [
                  'SCOPE 2',
                  Object.keys(list).length ? Number(list.onProcessScope.scope2.toFixed(2)) : 0,
                ],
                [
                  'SCOPE 3',
                  Object.keys(list).length ? Number(list.onProcessScope.scope3.toFixed(2)) : 0,
                ],
              ]}
              options={pieoptions}
              width={'100%'}
              height={'327px'}
              getChartWrapper={(w) => (processScopePieChart.current = w)}
            />
            <Chart
              chartType='BarChart'
              width='100%'
              height='339px'
              data={[
                ['', '', { role: 'style' }],
                [
                  'CO2 HEMP SEQUESTRATION',
                  Object.keys(list).length
                    ? Number(list.onProcessScope.co2HempSEQUESTRATION.toFixed(2))
                    : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.onProcessScope?.co2HempSEQUESTRATION > 0
                      ? CHART_COLOR.redColor
                      : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
                [
                  'SCOPE 1',
                  Object.keys(list).length ? Number(list.onProcessScope.scope1.toFixed(2)) : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.onProcessScope?.scope1 > 0 ? CHART_COLOR.redColor : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
                [
                  'SCOPE 2',
                  Object.keys(list).length ? Number(list.onProcessScope.scope2.toFixed(2)) : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.onProcessScope?.scope2 > 0 ? CHART_COLOR.redColor : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
                [
                  'SCOPE 3',
                  Object.keys(list).length ? Number(list.onProcessScope.scope3.toFixed(2)) : 0,
                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                    list?.onProcessScope?.scope3 > 0 ? CHART_COLOR.redColor : CHART_COLOR.greenColor
                  }; fill-opacity: 0.95;`,
                ],
              ]}
              options={scopeoptions}
              getChartWrapper={(w) => (processcopeBarChart.current = w)}
            />
          </>
        )}
      </Typography>
      <Loader openLoader={loading} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='d-flex-a flex-sb pb-24 bottom-border mb-24'>
          {scenarioId && screnarioList.length ? (
            <Typography component={'div'} className='d-center'>
              <Typography className='dashboard-title'>
                {
                  screnarioList[screnarioList.findIndex((item: any) => item._id === scenarioId)]
                    .name
                }
              </Typography>
              <Typography className='dashboard-date'>
                Created on:{' '}
                {moment(
                  screnarioList[screnarioList.findIndex((item: any) => item._id === scenarioId)]
                    .createdAt,
                ).format('DD MMM YYYY')}
              </Typography>
            </Typography>
          ) : (
            <Typography component={'div'} className='d-center' />
          )}
          <Typography component={'div'} className='custom-form'>
            <Button className='save-btn' onClick={handleOpen1}>
              Select Scenario
            </Button>
            <Modal
              className='addfacilitymodal'
              open={open1}
              onClose={handleClose1}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'>
              <Box sx={style}>
                <IconButton onClick={handleClose1} className='close-btn'>
                  <img src={Close} alt='close' />
                </IconButton>
                <Typography component={'div'} sx={{ position: 'relative' }}>
                  <Typography component={'div'} className='modal-normal-title'>
                    Scenario
                  </Typography>
                  <Typography component={'div'} className='custom-table'>
                    <TableContainer component={Paper}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className='blue-header'>
                              <Typography component={'div'} className='d-flex-a'>
                                <Typography
                                  component={'div'}
                                  className='arrow-box'
                                  onClick={() => sortByFunction('name')}>
                                  <img src={ArrowDown} alt='arrow' className='d-arrow' />
                                  <img src={ArrowDown} alt='arrow' className='u-arrow' />
                                </Typography>
                                <Typography sx={{ color: '#fff' }} className='th-title-white'>
                                  Name
                                </Typography>
                              </Typography>
                            </TableCell>
                            <TableCell className='blue-header' align='center'>
                              <Typography
                                component={'div'}
                                className='d-flex-a'
                                justifyContent={'center'}>
                                <Typography
                                  component={'div'}
                                  className='arrow-box'
                                  onClick={() => sortByFunction('date')}>
                                  <img src={ArrowDown} alt='arrow' className='d-arrow' />
                                  <img src={ArrowDown} alt='arrow' className='u-arrow' />
                                </Typography>
                                <Typography
                                  sx={{ marginLeft: '10px !important' }}
                                  className='th-title-white'>
                                  Created Date
                                </Typography>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableLoader colsSpan={2} />
                          ) : screnarioList.length ? (
                            screnarioList.map((result: any, index: number) => (
                              <TableRow
                                key={index}
                                className={scenarioId === result._id ? 'active-row' : 'false'}>
                                <TableCell
                                  onClick={() => {
                                    setScenarioId(result?._id);
                                    handleClose1();
                                  }}
                                  className='w-30'
                                  sx={{ cursor: 'pointer' }}>
                                  {result?.name}
                                </TableCell>
                                <TableCell
                                  align='center'
                                  sx={{ cursor: 'pointer' }}
                                  className='w-40'
                                  onClick={() => {
                                    setScenarioId(result?._id);
                                    handleClose1();
                                  }}>
                                  {moment(result.createdAt).format('MMM DD, YYYY')}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell align='center' colSpan={2}>
                                <Typography component={'div'} className='noRecordsText'>
                                  No Records Found!
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Typography>
                </Typography>
              </Box>
            </Modal>
          </Typography>
        </Typography>
      </Grid>
      {scenarioId ? (
        <Grid item md={12} sm={12} xs={12} className='dashboad-tab'>
          <TabContext value={value}>
            <Box className='bottom-border'>
              <Typography component={'div'} className='d-flex-a flex-sb flex-m-column'>
                <TabList
                  className='o2'
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                  variant='scrollable'
                  scrollButtons='auto'>
                  <Tab label='Results' value='1' />
                  <Tab label='Farm Carbon Footprint' value='2' />
                  <Tab label='Processing Carbon Footprint' value='3' />
                </TabList>
                <Typography component={'div'} className='o1'>
                  <Typography component={'div'} className=' in-flex'>
                    <Button
                      className='save-btn'
                      onClick={handleDownload}
                      sx={{
                        marginTop: '-10px',
                        paddingTop: '6px !important',
                        paddingBottom: '6px !important',
                      }}>
                      <img
                        src={DownloadPng}
                        style={{ width: '20px', height: '20px', marginRight: '10px' }}
                      />
                      Export LCA Report
                    </Button>
                  </Typography>
                </Typography>
              </Typography>
            </Box>
            <TabPanel value='1'>
              <Typography component={'div'} className='dashboard-batch'>
                <Typography component={'section'}>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12} textAlign={'right'}>
                      <Button className='save-btn' onClick={handleOpen}>
                        Calculated Output
                      </Button>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container columnSpacing={2.5}>
                        <Grid item md={7} sm={12} xs={12}>
                          <Typography component={'div'} className='result-chart mt-12'>
                            <Typography component={'div'} className='re-title'>
                              Net Carbon
                            </Typography>
                            <Typography
                              component={'div'}
                              sx={{ marginTop: '19px', position: 'relative' }}
                              style={{
                                border: '1px solid #E4E7EC',
                                borderRadius: '5px',
                                overflow: 'hidden',
                              }}>
                              <Grid container columnSpacing={2.5}>
                                <Grid item md={4} sm={12} xs={12}>
                                  <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height={'309px'}
                                    style={{ marginLeft: '20px' }}
                                    data={[
                                      [
                                        'Element',
                                        'Density',
                                        { role: 'annotation' },
                                        { role: 'style' },
                                      ],
                                      [
                                        Object.keys(list).length
                                          ? list?.resultAndCarbonFootPrint?.carbonFootPrint
                                              ?.corbonBalance?.[2]?.activities
                                          : '',
                                        Object.keys(list).length
                                          ? Number(
                                              list?.resultAndCarbonFootPrint?.carbonFootPrint?.corbonBalance?.[2]?.value.toFixed(
                                                2,
                                              ),
                                            )
                                          : 0,
                                        `${
                                          Object.keys(list).length
                                            ? roundOfData1(
                                                list?.resultAndCarbonFootPrint?.carbonFootPrint
                                                  ?.corbonBalance?.[2]?.value,
                                              )
                                            : ''
                                        }`,
                                        `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.greenColor}; fill-opacity: 0.95;`,
                                      ],
                                      // ['', '', ''],
                                    ]}
                                    options={{
                                      chartArea: { width: '65%', fontName: 'Poppins-Regular' },
                                      legend: { position: 'none' },
                                    }}
                                  />
                                </Grid>
                                <Grid item md={8} sm={12} xs={12}>
                                  <Chart
                                    chartType='PieChart'
                                    data={[
                                      ['Task', 'Hours per Day'],
                                      [
                                        'Oil',
                                        Object.keys(list).length
                                          ? list?.lcaOilResult?.corbonBalance[2].value >= 0
                                            ? 0
                                            : Number(
                                                (
                                                  list?.lcaOilResult?.corbonBalance[2].value * -1
                                                ).toFixed(2),
                                              )
                                          : 0,
                                      ],
                                      [
                                        'Cake',
                                        Object.keys(list).length
                                          ? list?.lcaCakeResult?.corbonBalance[2].value >= 0
                                            ? 0
                                            : Number(
                                                (
                                                  list?.lcaCakeResult?.corbonBalance[2].value * -1
                                                ).toFixed(2),
                                              )
                                          : 0,
                                      ],
                                      [
                                        'Hull',
                                        Object.keys(list).length
                                          ? list?.lcaHullResult?.corbonBalance[2].value >= 0
                                            ? 0
                                            : Number(
                                                (
                                                  list?.lcaHullResult?.corbonBalance[2].value * -1
                                                ).toFixed(2),
                                              )
                                          : 0,
                                      ],
                                      // [
                                      //   'Bast Fiber',
                                      //   Object.keys(list).length
                                      //     ? list?.lcaBastFiberResult?.corbonBalance[2].value >= 0
                                      //       ? 0
                                      //       : Number(
                                      //           (
                                      //             list?.lcaBastFiberResult?.corbonBalance[2].value *
                                      //             -1
                                      //           ).toFixed(2),
                                      //         )
                                      //     : 0,
                                      // ],
                                      [
                                        'Long Bast Fiber',
                                        Object.keys(list).length
                                          ? list?.lcaLongBastFiberesult?.corbonBalance[2].value >= 0
                                            ? 0
                                            : Number(
                                                (
                                                  list?.lcaLongBastFiberesult?.corbonBalance[2]
                                                    .value * -1
                                                ).toFixed(2),
                                              )
                                          : 0,
                                      ],
                                      [
                                        'Short Bast Fiber',
                                        Object.keys(list).length
                                          ? list?.lcaShortBastFiberesult?.corbonBalance[2].value >=
                                            0
                                            ? 0
                                            : Number(
                                                (
                                                  list?.lcaShortBastFiberesult?.corbonBalance[2]
                                                    .value * -1
                                                ).toFixed(2),
                                              )
                                          : 0,
                                      ],
                                      [
                                        'Hurd',
                                        Object.keys(list).length
                                          ? list?.lcaHurdResult?.corbonBalance[2].value >= 0
                                            ? 0
                                            : Number(
                                                (
                                                  list?.lcaHurdResult?.corbonBalance[2].value * -1
                                                ).toFixed(2),
                                              )
                                          : 0,
                                      ],
                                      [
                                        'Dust',
                                        Object.keys(list).length
                                          ? list?.lcaDustResult?.corbonBalance[2].value >= 0
                                            ? 0
                                            : Number(
                                                (
                                                  list?.lcaDustResult?.corbonBalance[2].value * -1
                                                ).toFixed(2),
                                              )
                                          : 0,
                                      ],
                                    ]}
                                    options={{
                                      legend: { position: 'right' },
                                    }}
                                    width={'100%'}
                                    height={'339px'}
                                  />
                                </Grid>
                              </Grid>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={5} sm={12} xs={12}>
                          <Typography component={'div'} className='result-chart mt-12'>
                            <Typography component={'div'} className='re-title'>
                              Proportion Of Emissions Per Activity
                            </Typography>
                            <Typography
                              component={'div'}
                              sx={{ marginTop: '19px', position: 'relative' }}
                              style={{
                                border: '1px solid #E4E7EC',
                                borderRadius: '5px',
                                overflow: 'hidden',
                              }}>
                              {!tableLoader && (
                                <Chart
                                  chartType='BarChart'
                                  width='100%'
                                  height='339px'
                                  data={[
                                    ['', '', { role: 'style' }],
                                    [
                                      'Pre-Farm',
                                      Object.keys(list).length
                                        ? Number(
                                            (list?.emissionProportion?.preFarm * 100).toFixed(2),
                                          )
                                        : 0,
                                      `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                                    ],
                                    [
                                      'On-Farm',
                                      Object.keys(list).length
                                        ? Number(
                                            (list?.emissionProportion?.onFarm * 100).toFixed(2),
                                          )
                                        : 0,
                                      `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                                    ],
                                    [
                                      'Process',
                                      Object.keys(list).length
                                        ? Number(
                                            (list?.emissionProportion?.onProcess * 100).toFixed(2),
                                          )
                                        : 0,
                                      `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                                    ],
                                    [
                                      'Post Process',
                                      Object.keys(list).length
                                        ? Number(
                                            (list?.emissionProportion?.postProcess * 100).toFixed(
                                              2,
                                            ),
                                          )
                                        : 0,
                                      `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${CHART_COLOR.redColor}; fill-opacity: 0.95;`,
                                    ],
                                  ]}
                                  options={options}
                                />
                              )}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
                <Modal
                  className='addfacilitymodal'
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'>
                  <Box sx={style}>
                    <IconButton onClick={handleClose} className='close-btn'>
                      <img src={Close} alt='close' />
                    </IconButton>
                    <Typography component={'div'} sx={{ position: 'relative' }}>
                      <Typography component={'div'} className='modal-normal-title'>
                        Calculated Output
                      </Typography>
                      <ResultTable
                        data={
                          Object.keys(list).length ? list?.resultAndCarbonFootPrint?.result : []
                        }
                        loader={tableLoader}
                      />
                    </Typography>
                  </Box>
                </Modal>
                <Typography component={'section'} className='mt-30'>
                  <Grid container columnSpacing={2} rowSpacing={2.5}>
                    <Grid item md={12} sm={12} xs={12}>
                      <CorbonFootPrintWithLca
                        data={Object.keys(list).length ? list : []}
                        loader={tableLoader}
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </Typography>
            </TabPanel>
            <TabPanel value='2'>
              <Typography component={'div'} className='dashboard-batch'>
                <Typography component={'section'}>
                  <Grid container columnSpacing={2.5} rowSpacing={2.5}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='result-chart '
                        sx={{ minHeight: '450px !important' }}>
                        <Grid container columnSpacing={8}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='re-title'>
                              Carbon Balance For Scope
                            </Typography>
                          </Grid>
                          <Grid item md={6} sm={12} xs={12}>
                            <Typography
                              component={'div'}
                              sx={{ marginTop: '19px', position: 'relative' }}
                              style={{
                                border: '1px solid #E4E7EC',
                                borderRadius: '5px',
                                overflow: 'hidden',
                              }}>
                              {!tableLoader && (
                                <Chart
                                  chartType='PieChart'
                                  data={[
                                    ['Task', 'Hours per Day'],
                                    [
                                      'SCOPE 1',
                                      Object.keys(list).length
                                        ? Number(list.farmerScope.scope1.toFixed(2))
                                        : 0,
                                    ],
                                    [
                                      'SCOPE 2',
                                      Object.keys(list).length
                                        ? Number(list.farmerScope.scope2.toFixed(2))
                                        : 0,
                                    ],
                                    [
                                      'SCOPE 3',
                                      Object.keys(list).length
                                        ? Number(list.farmerScope.scope3.toFixed(2))
                                        : 0,
                                    ],
                                  ]}
                                  options={pieoptions}
                                  width={'100%'}
                                  height={'327px'}
                                />
                              )}
                            </Typography>
                          </Grid>
                          <Grid item md={6} sm={12} xs={12} sx={{ paddingTop: '20px' }}>
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: '346px' }} aria-label='simple table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      className='w-40'
                                      align='center'
                                      sx={tableCorbon.corbon}>
                                      Carbon Balance
                                    </TableCell>
                                    <TableCell
                                      className='w-40'
                                      align='center'
                                      sx={tableCorbon.corbon}>
                                      Activities
                                    </TableCell>
                                    <TableCell
                                      className='w-20'
                                      align='center'
                                      sx={tableCorbon.corbon}>
                                      Value
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.scope1} align='center'>
                                      SCOPE 1
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope1} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope1} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.farmerScope?.scope1)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.scope2} align='center'>
                                      SCOPE 2
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope2} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope2} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.farmerScope?.scope2)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.scope3} align='center'>
                                      SCOPE 3
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope3} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope3} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.farmerScope?.scope3)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.co2} align='center'>
                                      CO2 HEMP SEQUESTRATION
                                    </TableCell>
                                    <TableCell sx={tableCorbon.co2} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.co2} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.farmerScope?.co2HempSEQUESTRATION)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={2.5} rowSpacing={2.5} justifyContent={'center'}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='result-chart mt-24'>
                        <Typography component={'div'} className='re-title'>
                          Carbon Balance For Scope
                        </Typography>
                        <Typography
                          component={'div'}
                          sx={{ marginTop: '19px', position: 'relative' }}
                          style={{
                            border: '1px solid #E4E7EC',
                            borderRadius: '5px',
                            overflow: 'hidden',
                          }}>
                          {!tableLoader ? (
                            <Chart
                              chartType='BarChart'
                              width='100%'
                              height='339px'
                              data={[
                                ['', '', { role: 'style' }],
                                [
                                  'CO2 HEMP SEQUESTRATION',
                                  Object.keys(list).length
                                    ? Number(list.farmerScope.co2HempSEQUESTRATION.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.farmerScope?.co2HempSEQUESTRATION > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                                [
                                  'SCOPE 1',
                                  Object.keys(list).length
                                    ? Number(list.farmerScope.scope1.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.farmerScope?.scope1 > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                                [
                                  'SCOPE 2',
                                  Object.keys(list).length
                                    ? Number(list.farmerScope.scope2.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.farmerScope?.scope2 > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                                [
                                  'SCOPE 3',
                                  Object.keys(list).length
                                    ? Number(list.farmerScope.scope3.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.farmerScope?.scope3 > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                              ]}
                              options={scopeoptions}
                            />
                          ) : (
                            <Typography />
                          )}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography component={'section'} className='mt-40'>
                  <Grid item md={12} sm={12}>
                    <CorbonFootPrintWithFormerScope
                      data={Object.keys(list).length ? list : []}
                      loader={tableLoader}
                    />
                  </Grid>
                </Typography>
              </Typography>
            </TabPanel>
            <TabPanel value='3'>
              <Typography component={'div'} className='dashboard-batch'>
                <Typography component={'section'}>
                  <Grid container columnSpacing={2.5} rowSpacing={2.5}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='result-chart '
                        sx={{ minHeight: '450px !important' }}>
                        <Grid container columnSpacing={8}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='re-title'>
                              Carbon Balance For Scope
                            </Typography>
                          </Grid>
                          <Grid item md={6} sm={12} xs={12}>
                            <Typography
                              component={'div'}
                              sx={{ marginTop: '19px', position: 'relative' }}
                              style={{
                                border: '1px solid #E4E7EC',
                                borderRadius: '5px',
                                overflow: 'hidden',
                              }}>
                              {!tableLoader && (
                                <Chart
                                  chartType='PieChart'
                                  data={[
                                    ['Task', 'Hours per Day'],
                                    [
                                      'SCOPE 1',
                                      Object.keys(list).length
                                        ? Number(list.onProcessScope.scope1.toFixed(2))
                                        : 0,
                                    ],
                                    [
                                      'SCOPE 2',
                                      Object.keys(list).length
                                        ? Number(list.onProcessScope.scope2.toFixed(2))
                                        : 0,
                                    ],
                                    [
                                      'SCOPE 3',
                                      Object.keys(list).length
                                        ? Number(list.onProcessScope.scope3.toFixed(2))
                                        : 0,
                                    ],
                                  ]}
                                  options={pieoptions}
                                  width={'100%'}
                                  height={'327px'}
                                />
                              )}
                            </Typography>
                          </Grid>
                          <Grid item md={6} sm={12} xs={12} sx={{ paddingTop: '20px' }}>
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: '346px' }} aria-label='simple table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      className='w-40'
                                      align='center'
                                      sx={tableCorbon.corbon}>
                                      Carbon Balance
                                    </TableCell>
                                    <TableCell
                                      className='w-40'
                                      align='center'
                                      sx={tableCorbon.corbon}>
                                      Activities
                                    </TableCell>
                                    <TableCell
                                      className='w-20'
                                      align='center'
                                      sx={tableCorbon.corbon}>
                                      Value
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.scope1} align='center'>
                                      SCOPE 1
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope1} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope1} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.onProcessScope?.scope1)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.scope2} align='center'>
                                      SCOPE 2
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope2} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope2} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.onProcessScope?.scope2)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.scope3} align='center'>
                                      SCOPE 3
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope3} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.scope3} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.onProcessScope?.scope3)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={tableCorbon.co2} align='center'>
                                      CO2 HEMP SEQUESTRATION
                                    </TableCell>
                                    <TableCell sx={tableCorbon.co2} align='center'>
                                      Kg CO<sub>2</sub>/year
                                    </TableCell>
                                    <TableCell sx={tableCorbon.co2} align='center'>
                                      {Object.keys(list).length
                                        ? roundOfData(list?.onProcessScope?.co2HempSEQUESTRATION)
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={2.5} rowSpacing={2.5} justifyContent={'center'}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='result-chart mt-24'>
                        <Typography component={'div'} className='re-title'>
                          Carbon Balance For Scope
                        </Typography>
                        <Typography
                          component={'div'}
                          sx={{ marginTop: '19px', position: 'relative' }}
                          style={{
                            border: '1px solid #E4E7EC',
                            borderRadius: '5px',
                            overflow: 'hidden',
                          }}>
                          {!tableLoader ? (
                            <Chart
                              chartType='BarChart'
                              width='100%'
                              height='339px'
                              data={[
                                ['', '', { role: 'style' }],
                                [
                                  'CO2 HEMP SEQUESTRATION',
                                  Object.keys(list).length
                                    ? Number(list.onProcessScope.co2HempSEQUESTRATION.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.onProcessScope?.co2HempSEQUESTRATION > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                                [
                                  'SCOPE 1',
                                  Object.keys(list).length
                                    ? Number(list.onProcessScope.scope1.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.onProcessScope?.scope1 > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                                [
                                  'SCOPE 2',
                                  Object.keys(list).length
                                    ? Number(list.onProcessScope.scope2.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.onProcessScope?.scope2 > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                                [
                                  'SCOPE 3',
                                  Object.keys(list).length
                                    ? Number(list.onProcessScope.scope3.toFixed(2))
                                    : 0,
                                  `stroke-color:#562611; stroke-opacity: 1; stroke-width: 2;fill-color: ${
                                    list?.onProcessScope?.scope3 > 0
                                      ? CHART_COLOR.redColor
                                      : CHART_COLOR.greenColor
                                  }; fill-opacity: 0.95;`,
                                ],
                              ]}
                              options={scopeoptions}
                            />
                          ) : (
                            <Typography />
                          )}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography component={'section'} className='mt-40'>
                  <Grid item md={12} sm={12}>
                    <CorbonFootPrintWithOnProcessScope
                      data={Object.keys(list).length ? list : []}
                      loader={tableLoader}
                    />
                  </Grid>
                </Typography>
              </Typography>
            </TabPanel>
          </TabContext>
        </Grid>
      ) : (
        <Typography />
      )}
    </Grid>
  );
};

export default Dashboard;
