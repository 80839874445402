import React from 'react';
import _ from 'lodash';
import PublicRoutes from 'routes/PublicRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';
import { useAppSelector } from 'store/hooks';

const AppRoutes = () => {
  const { userInfo: userDetail }: any = useAppSelector((store: any) => store.userLogin);
  if (!_.isEmpty(userDetail)) {
    return <PrivateRoutes />;
  } else {
    return <PublicRoutes />;
  }
};

export default AppRoutes;
