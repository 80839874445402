import { Button, FormControl, Grid, InputLabel, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import React, { useEffect, useState } from 'react';
import { PrivateRoutes } from 'routes/routeConstans';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { userForm } from 'types';
import { userSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect';
import { addNewData, updateData, getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import _ from 'lodash';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/Loader';

const AddUser = () => {
  const [editRow, setEditRow] = useState<any>({});
  const [loader, setLoader] = React.useState(false);
  const [userType, setUserType] = React.useState('');
  const { userInfo } = useAppSelector((store: any) => store.userLogin);
  const location = useLocation();
  const navigate = useNavigate();
  const { LIST } = PrivateRoutes.USERS;

  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });

  const { handleSubmit, control, formState, reset, setValue, setError } = useForm<userForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      userType: '',
      status: true,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver<any>(userSchema),
  });
  const submitForm = (data: any) => {
    // eslint-disable-next-line prefer-const
    let payloadObj: any = {
      firstName: data['firstName'],
      lastName: data['lastName'],
      roleId: data['userType'],
      email: data['email'],
      status: data['status'],
      organization: userInfo?.organizationId,
    };
    if (!_.isEmpty(editRow)) {
      if (data['password']) {
        const regex = /[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;
        if (
          /[A-Z]/.test(data['password']) &&
          data['password'].length >= 8 &&
          regex.test(data['password'])
        ) {
          payloadObj['updatePassword'] = true;
          payloadObj['password'] = data['password'];
        } else {
          setError('password', {
            message:
              'Password must contain one uppercase, one lowercase, one number and one special case character',
            type: 'custom',
          });
          return;
        }
      } else {
        payloadObj['updatePassword'] = false;
        delete payloadObj['password'];
      }
      setLoader(true);
      updateData(editRow._id, payloadObj, MASTER_ENDPOINT.user, true)
        .then(() => {
          navigate('/manage-users');
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      if (data['password']) {
        const regex = /[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;
        if (
          /[A-Z]/.test(data['password']) &&
          data['password'].length >= 8 &&
          regex.test(data['password'])
        ) {
          setLoader(true);
          addNewData({ ...payloadObj, password: data['password'] }, MASTER_ENDPOINT.user, '', true)
            .then(() => {
              setLoader(false);
              navigate('/manage-users');
            })
            .catch((err) => {
              setLoader(false);
              console.log(err);
            });
        } else {
          setError('password', {
            message:
              'Password must contain one uppercase, one lowercase, one number and one special case character',
            type: 'custom',
          });
        }
      } else {
        setError('password', {
          message: 'Password is required',
          type: 'custom',
        });
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    getAllListData(
      `${MASTER_ENDPOINT.Usertype}?roleIds=${userDetail?.roleId === 1 ? '2,3,4' : '3,4'}`,
    )
      .then((res) => {
        setUserType(res.data);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setLoader(true);
    if (!_.isEmpty(location.state)) {
      const editData = location.state.row;
      setEditRow(editData);
      setValue('firstName', editData['firstName']);
      setValue('lastName', editData['lastName']);
      setValue('email', editData['login']['email']);
      setValue('status', editData['status']);
      setValue('userType', editData['role']['id']);
      setLoader(false);
    }
  }, [location.state]);
  return (
    <Grid container>
      <Loader openLoader={loader} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='d-flex-a flex-sb bottom-border pb-24'>
          <Typography className='commontitle'>
            {!_.isEmpty(editRow) ? 'Edit users' : 'Add users'}
          </Typography>
          <Typography component={'div'} className='inline-flex'>
            <Link to={LIST}>
              <Button className='cancel-btn btn-2r' onClick={() => reset()}>
                Cancel
              </Button>
            </Link>
            <Button className='save-btn' onClick={handleSubmit(submitForm)}>
              Save
            </Button>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='form-box' sx={{ padding: '24px !important' }}>
          <Grid container>
            <Grid item md={7} sm={12} xs={12}>
              <form className='custom-form'>
                <Grid container columnSpacing={4} rowSpacing={2.5}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='firstName'
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label='First Name *'
                            className='custom-input'
                            onChange={onChange}
                            ref={ref}
                            value={value}
                            name={name}
                            error={formState?.errors?.firstName?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='lastName'
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label='Last Name *'
                            className='custom-input'
                            onChange={onChange}
                            ref={ref}
                            value={value}
                            name={name}
                            error={formState?.errors?.lastName?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='email'
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label='Email Address *'
                            className='custom-input'
                            onChange={onChange}
                            ref={ref}
                            value={value}
                            name={name}
                            error={formState?.errors?.email?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='password'
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label={!_.isEmpty(editRow) ? 'password' : 'Password *'}
                            className='custom-input passwordText'
                            type='password'
                            onChange={onChange}
                            ref={ref}
                            value={value}
                            name={name}
                            error={formState?.errors?.password?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <FormControl fullWidth className='custom-input'>
                      <InputLabel id='demo-simple-select-label'>User Type *</InputLabel>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='userType'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              onChange={onChange}
                              value={String(value)}
                              name={name}
                              error={formState.errors.userType?.message}
                              options={userType}
                              labelKey='name'
                              valueKey={'id'}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue={true}
                      name='status'
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <FormControl className='custom-input'>
                            <FormLabel id='demo-row-radio-buttons-group-label'>Status</FormLabel>
                            <RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label'>
                              <FormControlLabel
                                control={
                                  <Radio
                                    name={name}
                                    value={true}
                                    checked={value === true ? true : false}
                                    ref={ref}
                                    onChange={(e) => {
                                      onChange(e.target.checked);
                                    }}
                                  />
                                }
                                label='Active'
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    name={name}
                                    value={false}
                                    checked={value === false ? true : false}
                                    ref={ref}
                                    onChange={(e) => {
                                      onChange(e.target.checked === true ? false : true);
                                    }}
                                  />
                                }
                                label='Inactive'
                              />
                            </RadioGroup>
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddUser;
